import React from 'react';
import RNumberFormat from 'react-number-format';
import {
  formatToPhone,
  formatToCNPJ,
  formatToCPF,
  formatToDate,
  formatToDateTime
} from 'brazilian-values';
import { parseISO, format } from 'date-fns';

const cnpjFormat = '##.###.###/####-##';
const cpfFormat = '###.###.###-##';

const getFormatedPhone = (value = '') => {
  const limited = value.replace(/-|\(|\)| /g, '').substring(0, 11);
  return formatToPhone(limited);
};

const CustomNumberFormat = ({ verbose = false, name, onChange, inputRef, ...rest }) => (
  <RNumberFormat
    {...rest}
    getInputRef={inputRef}
    onValueChange={values => {
      const value = verbose ? values.value : values.floatValue;
      onChange({
        target: {
          name,
          value: value === undefined ? '' : value
        }
      });
    }}
  />
);

export const MoneyFormat = props => (
  <CustomNumberFormat
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale={true}
    allowNegative={false}
    prefix="R$ "
    {...props}
  />
);

export const CnpjFormat = props => <CustomNumberFormat verbose format={cnpjFormat} {...props} />;

export const CpfFormat = props => <CustomNumberFormat verbose format={cpfFormat} {...props} />;

export const DocumentFormat = props => (
  <CustomNumberFormat
    {...props}
    verbose
    format={(v = '') => {
      const substringValue = String(v).substring(0, 14);
      return substringValue.length > 11
        ? formatToCNPJ(substringValue)
        : formatToCPF(substringValue);
    }}
  />
);

export const PhoneFormat = props => (
  <CustomNumberFormat verbose format={(v = '') => formatToPhone(v.substring(0, 11))} {...props} />
);

export const PhoneTextFormat = ({ value }) => (
  <NumberFormat value={value} displayType="text" format={getFormatedPhone} />
);

export const CreditCardFormat = props => (
  <CustomNumberFormat verbose {...props} format="####.####.####.####" />
);

export const CvvFormat = props => <CustomNumberFormat verbose {...props} format="####" />;

export const CreditCardExpirationFormat = props => (
  <CustomNumberFormat verbose {...props} format="##/##" />
);

export const HourMinuteFormat = ({ inputRef, ...props }) => (
  <RNumberFormat {...props} format="##:##" getInputRef={inputRef} />
);

export const MeasureFormat = props => (
  <CustomNumberFormat
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale={false}
    allowNegative={false}
    {...props}
  />
);

export const NumberFormat = props => (
  <CustomNumberFormat decimalScale={0} fixedDecimalScale={false} allowNegative={false} {...props} />
);

export const ZipcodeFormat = props => <CustomNumberFormat verbose {...props} format="##.###-###" />;

export const DateHourBR = ({ value }) => formatToDateTime(transformToDate(value));

export const DateFormatBR = ({ value }) => formatToDate(transformToDate(value));

export const DayMonthFormatBR = ({ value }) => format(transformToDate(value), 'dd/MM');

export const MonthYearFormatBR = ({ value }) => format(transformToDate(value), 'MM/yyyy');

export const DateFormatStringToBR = ({ value }) => formatToDate(transformToDate(value));

export const VerboseFormatISODateTimeString = dateISOString =>
  typeof dateISOString === 'string' && dateISOString.length > 0
    ? formatToDateTime(new Date(parseISO(dateISOString)))
        .split(' ')
        .join(' às ') + 'H'
    : '';

export const transformToDate = value =>
  typeof value === Date || !value ? value : new Date(parseISO(value));
