import { useContext } from 'react';
import copy from 'copy-to-clipboard';
import { AppContext } from 'components/Context';

const useCopy = ({ text }) => {
  const { actions } = useContext(AppContext);
  const { handleMessage } = actions;

  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();

    copy(text);
    handleMessage('Copiado');
  };

  return [onClick];
};

export default useCopy;
