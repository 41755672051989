import client from '.';
import MUTATION_CHANGE_SHIPPER from 'graphql/mutations/changeShipper';
import MUTATION_LOGOUT from 'graphql/mutations/logout';
import { HOME_PATH } from './constants';
import getErrorMessage from 'shared/helpers/getErrorMessage';

export async function logout() {
  try {
    await client.mutate({ mutation: MUTATION_LOGOUT });
  } catch {
  } finally {
    localStorage.clear();
    window.location.assign(HOME_PATH);
  }
}

export const selectShipper = function(shipperId) {
  const variables = { shipper_id: shipperId };
  return client
    .mutate({ mutation: MUTATION_CHANGE_SHIPPER, variables })
    .then(({ data }) => {
      return data.changeShipper.token;
    })
    .catch(e => {
      throw getErrorMessage(e);
    });
};
