import React, { memo, useContext } from 'react';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import BadgeButton from '../BadgeButton';
import Menu from 'components/Menu';
import { MenuDividerText, MenuItemText } from 'components/Menu/styled';
import useMenu from 'hooks/useMenu';
import { AppContext } from 'components/Context';
import CopyButton from 'components/CopyButton';
import useDialog from 'hooks/useDialog';
import FeedbackDialog from 'components/FeedbackDialog';

const HelpMenu = () => {
  const {
    state: { ticketCountAwaitingResponse }
  } = useContext(AppContext);
  const { anchorEl, handleOpenMenu, handleCloseMenu } = useMenu();
  const { isOpen, handleClose, handleOpen } = useDialog(false);
  const menuDefaultOptions = [
    {
      href: '/central-de-atendimento',
      text: 'Central de Atendimento',
      icon: 'comment'
    },
    {
      href: 'https://centraldofrete.zendesk.com/hc/pt-br',
      text: 'Central de Ajuda',
      icon: 'help'
    },
    {
      onClick: () => window.zE('webWidget', 'open'),
      text: 'Entrar em contato',
      icon: 'forum'
    },
    { onClick: () => handleOpen(), text: 'Enviar sugestão/feedback', icon: 'send' },
    {
      href: 'mailto:falecom@centraldofrete.com',
      text: 'falecom@centraldofrete.com',
      icon: 'mail',
      renderSecondaryAction: () => (
        <CopyButton text="falecom@centraldofrete.com" showInnerText={false} variant="text" />
      )
    }
  ];

  return (
    <>
      <BadgeButton
        ariaLabel="Ajuda"
        aria-controls="help-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        badgeContent={ticketCountAwaitingResponse}
      >
        <ContactSupportIcon />
      </BadgeButton>
      <FeedbackDialog isOpen={isOpen} onClose={handleClose} />

      <Menu
        id="help-menu"
        anchorEl={anchorEl}
        handleClose={handleCloseMenu}
        items={menuDefaultOptions}
        menuItemProps={item => ({
          ...(item.href === '/central-de-atendimento'
            ? { badgeCount: ticketCountAwaitingResponse, badgeColor: 'error' }
            : {})
        })}
      >
        <MenuDividerText>Horário de atendimento</MenuDividerText>
        <MenuItemText>De segunda a sexta das 9h às 18h</MenuItemText>
      </Menu>
    </>
  );
};

export default memo(HelpMenu);
