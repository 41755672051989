import React from 'react';
import client from '.';
import { isSameDay } from 'date-fns';
import { withRouter } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import QUERY_CHECK_STATUS_SHIPPER from 'graphql/queries/checkStatusShipper';
import MUTATION_RESEND_EMAIL from 'graphql/mutations/resendValidateEmailCode';
import { selectShipper } from 'api/user';
import { TOKEN } from 'api/constants';

const RedirectFinancialButtonPayload = withRouter(({ history, invoiceId }) => (
  <Button
    style={{ whiteSpace: 'nowrap' }}
    onClick={() => history.push(`/financeiro/${invoiceId || ''}`)}
  >
    Regularizar Débito
  </Button>
));

const RedirectPaymentUpdateButtonPayload = withRouter(({ history }) => (
  <Button
    style={{ whiteSpace: 'nowrap' }}
    onClick={() =>
      history.push({
        pathname: '/assinatura-atualizar-forma-de-pagamento',
        state: { params: { pageReturn: '/' } }
      })
    }
  >
    Atualizar dados de pagamento
  </Button>
));

const RedirectValidateEmail = withRouter(({ history, ignore_email_verification }) => {
  const [resendValidateEmailCode] = useMutation(MUTATION_RESEND_EMAIL);

  const handleValidateEmail = () => {
    if (ignore_email_verification) {
      try {
        resendValidateEmailCode();
      } catch (error) {}
    }
    history.push({
      pathname: '/confirmar-email',
      state: { params: { pageReturn: '/' } }
    });
  };

  return (
    <Button style={{ whiteSpace: 'nowrap' }} onClick={handleValidateEmail}>
      Validar e-mail
    </Button>
  );
});

const saveSenderAddress = async ({ shipper_id, zipcode, address, default_cargo_types }) => {
  try {
    const current_shipper = localStorage.getItem('current_shipper');
    if (!current_shipper) {
      localStorage.setItem('current_shipper', shipper_id);
    }
    const stringfiedSender = localStorage.getItem('sender');
    const sender = (stringfiedSender && JSON.parse(stringfiedSender)) || {};
    const newSender = {
      ...sender,
      [shipper_id]: {
        zipcode,
        address,
        default_cargo_types
      }
    };
    localStorage.setItem('sender', JSON.stringify(newSender));
    if (localStorage.getItem('renewtoken') === 'renewtoken') {
      const token = await selectShipper(shipper_id);
      localStorage.setItem(TOKEN, token);
      localStorage.removeItem('renewtoken');
    }
  } catch (error) {
    console.error(error);
  }
};

const isToastNotHidden = status => {
  const hiddenWarning = JSON.parse(localStorage.getItem('hiddenWarning')) || {};
  if (hiddenWarning[status]) {
    const date = new Date(hiddenWarning[status]);
    const today = new Date();
    return !isSameDay(date, today);
  }
  return true;
};

export default function() {
  return client
    .query({ query: QUERY_CHECK_STATUS_SHIPPER, fetchPolicy: 'network-only' })
    .then(({ data }) => {
      const { checkStatusShipper } = data;
      const {
        shipper_id,
        group_id,
        status,
        message,
        invoices = [],
        zipcode,
        address,
        has_plan,
        has_cart,
        has_sales_order,
        has_refunds,
        has_renegotiations,
        has_conciliations,
        has_billing_enabled,
        cart_count = 0,
        ticket_count_awaiting_response = 0,
        ticket_count_awaiting_analyses = 0,
        balance = 0,
        conciliation_credits = 0,
        conciliation_debts = 0,
        default_cargo_types = [],
        email_verified_at,
        ignore_email_verification
      } = checkStatusShipper;

      const invoiceId = Array.isArray(invoices) && invoices.length === 1 && invoices[0].id;
      const hasInvoices = Array.isArray(invoices) && invoices.length > 0;

      saveSenderAddress({ shipper_id, zipcode, address, default_cargo_types });

      const toastProps = {
        status,
        message,
        actions: hasInvoices ? (
          <RedirectFinancialButtonPayload invoiceId={invoiceId} />
        ) : status === 'Falha Pagamento Plano' ? (
          <RedirectPaymentUpdateButtonPayload />
        ) : status === 'Validar Email' ? (
          <RedirectValidateEmail ignore_email_verification={ignore_email_verification} />
        ) : (
          <></>
        )
      };

      return {
        warningToast: status !== 'Ativo' && isToastNotHidden(status) && toastProps,
        has_plan,
        has_cart,
        has_sales_order,
        has_refunds,
        has_renegotiations,
        has_conciliations,
        has_billing_enabled,
        cart_count,
        ticket_count_awaiting_response,
        ticket_count_awaiting_analyses,
        email_verified_at,
        ignore_email_verification,
        balance,
        conciliation_credits,
        conciliation_debts,
        group_id
      };
    })
    .catch(e => {
      throw e;
    });
}
