import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
  position: relative;
`;

export const Dropzone = styled.div`
  cursor: pointer;
  min-height: 78px;
  color: #453e97;
  border-radius: 4px;
  margin-bottom: 16px;
  border: 1px solid #453e97;
  padding: 12px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px;

  & > .icon {
    margin-bottom: 8px;
    font-size: 32px;
  }
`;

export const Instruction = styled(Typography)`
  && {
    margin: 0;
  }
`;
export const LoaderWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  padding-top: 35px;
  width: 100%;
  background-color: rgba(255, 251, 251, 0.77);
  height: 100%;
  text-align: center;
`;

export const SelectedFilesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  & > div {
    margin: 4px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const InputFileWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
