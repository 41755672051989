import React, { Component } from 'react';
import {
  Button,
  FormControl,
  TextField,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { Container, Image } from './styled';
import { withAppContext } from 'components/Context';
import Logo from 'assets/logo.png';
import Oops from 'assets/Oops.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
import mutationSendFeedback from 'graphql/mutations/sendFeedback';
import client from 'api';
import { ERROR } from 'shared/constants/typeReportTicket';

class ErrorPage extends Component {
  state = {
    success: false,
    failedSend: false
  };

  async handleSubmit({ message }, { setSubmitting, resetForm }) {
    setSubmitting(true);
    try {
      const { errorObject } = this.props;
      if (errorObject) {
        await client.mutate({
          mutation: mutationSendFeedback,
          variables: {
            url: window.location.href,
            type: ERROR,
            subject: errorObject.error.toString(),
            message,
            stacktrace: errorObject.componentStack
          }
        });
      }

      this.setState({ success: true });
      resetForm();
    } catch (error) {
      this.setState({ failedSend: true });
    } finally {
      setSubmitting(false);
    }
  }

  renderMessage() {
    const { failedSend } = this.state;
    const objectMessage = {
      title: failedSend ? 'Falha no envio' : 'Mensagem enviada',
      message: failedSend
        ? 'Tente novamente mais tarde'
        : 'Sua mensagem foi enviada e o mais breve possível iremos analisar e resolver esta situação.'
    };
    return (
      <>
        <Typography
          variant="h5"
          component="h4"
          color={failedSend ? 'danger' : 'primary'}
          gutterBottom
        >
          {objectMessage.title}
        </Typography>
        <Typography component="p" color="inherit" gutterBottom>
          {objectMessage.message}
        </Typography>
        <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}>
          <Button
            color="primary"
            variant="contained"
            data-testid="feedbackSubmit"
            onClick={() => window.location.reload()}
          >
            <span>Recarregar página</span>
          </Button>
        </div>
      </>
    );
  }

  render() {
    const { success } = this.state;

    return (
      <Container className="errorPage">
        <Grid direction="column" justify="center" alignItems="center" container>
          <Grid item xs={12}>
            <Image src={Logo} alt="Central do Frete" />
          </Grid>
        </Grid>
        <Grid
          direction="row"
          justify="center"
          alignItems="center"
          container
          style={{ marginTop: '5vh' }}
        >
          <Grid item xs={12} sm={6}>
            <Image src={Oops} style={{ width: '100%', height: '30vh' }} alt="Ops!" />
          </Grid>
          <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
            {!success && (
              <div>
                <Typography variant="h5" component="h4" color="primary" gutterBottom>
                  Um erro aconteceu...
                </Typography>
                <Typography component="p" color="inherit" gutterBottom>
                  Mas não se preocupe, por aqui mesmo você pode reportar este erro e nós ficaremos
                  felizes para lhe auxiliar.
                </Typography>
                <Formik
                  initialValues={{ message: '' }}
                  validationSchema={Yup.object().shape({
                    message: Yup.string().required('Descreva sua experiência')
                  })}
                  onSubmit={(fields, other) => this.handleSubmit({ ...fields }, { ...other })}
                  render={({
                    values,
                    handleSubmit: handleSubmitForm,
                    handleChange,
                    isSubmitting,
                    errors,
                    setFieldValue,
                    touched,
                    ...rest
                  }) => (
                    <>
                      <FormControl component="fieldset" fullWidth>
                        <TextField
                          name="message"
                          onChange={handleChange}
                          fullWidth
                          style={{ marginTop: '15px' }}
                          error={errors.message}
                          helperText={errors.message === '' ? '' : errors.message}
                          multiline
                          rows={3}
                          value={values.message}
                          data-testid="feedbackMessage"
                          placeholder="Por favor, nos conte o que aconteceu..."
                        />
                      </FormControl>
                      <div
                        style={{
                          marginTop: '30px',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Button color="primary" onClick={() => window.location.reload(true)}>
                          <Typography component="span" color="primary" gutterBottom>
                            Recarregar página
                          </Typography>
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={isSubmitting}
                          data-testid="feedbackSubmit"
                          onClick={handleSubmitForm}
                        >
                          <span>Enviar</span>
                          {isSubmitting && (
                            <CircularProgress
                              size={20}
                              color="inherit"
                              style={{ marginRight: '12px' }}
                            />
                          )}
                        </Button>
                      </div>
                    </>
                  )}
                />
              </div>
            )}
            {success && this.renderMessage()}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withAppContext(ErrorPage);
