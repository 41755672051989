import { TOKEN } from './constants';
import parseSearchLocation, { stringify } from 'shared/helpers/parseSearchLocation';

export function getValueFromLocationOrStorage(key) {
  const parsedValues = parseSearchLocation(window.location.search);
  Object.entries(parsedValues).map(([key, value]) => localStorage.setItem(key, value));
  if (parsedValues['token']) {
    const { token, ...otherValues } = parsedValues;
    localStorage.setItem('renewtoken', 'renewtoken');
    window.location.search = stringify(otherValues);
  }
  return localStorage.getItem(key);
}

export function getToken() {
  return getValueFromLocationOrStorage(TOKEN);
}

export default getToken;
