export default message => {
  if (typeof message === 'string') {
    return message;
  }
  if (
    message &&
    message.graphQLErrors &&
    message.graphQLErrors.length > 0 &&
    typeof message.graphQLErrors[0].message === 'string'
  ) {
    return message.graphQLErrors[0].message;
  }
  if (message && message.message && typeof message.message === 'string') {
    return message.message.replace('GraphQL error:', '');
  }
};
