import React from 'react';
import ErrorPage from 'pages/ErrorPage';
import { getWithExpiry, setWithExpiry } from './storage';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, dataCatch: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    const chunk = /ChunkLoadError/;
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const chunkCssFailedMessage = /Loading CSS chunk [\d]+ failed/;
    if (
      error?.message &&
      (chunk.test(error.message) ||
        chunkFailedMessage.test(error.message) ||
        chunkCssFailedMessage.test(error.message))
    ) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 5000);
        window.location.reload(true);
      }
    }
    this.setState({
      dataCatch: {
        error,
        ...errorInfo
      }
    });
  }

  render() {
    const { hasError, dataCatch } = this.state;

    if (hasError) {
      return <ErrorPage errorObject={dataCatch} />;
    }

    return this.props.children;
  }
}
