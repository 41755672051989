import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

export const StyledMessage = styled.div`
  display: flex;
  align-items: center;

  .material-icons {
    margin-right: 8px;
  }
`;

export const Wrapper = styled(Snackbar)`
  & > div {
    ${({ variant }) => {
      switch (variant) {
        case 'error':
          return `background-color: ${red[600]}!important`;
        case 'warning':
          return `background-color: ${amber[700]}!important`;
        case 'success':
          return `background-color: ${green[600]}!important`;
        case 'info':
          return `background-color: #1976d2!important`;
        default:
          return 'opacity: .2';
      }
    }}
  }
`;
