import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useMutation } from 'react-apollo';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  Icon,
  IconButton,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Grid,
  TextField,
  withMobileDialog
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FileList from 'components/UploadFile/FileList';
import StyledCloseModalButton from '../StyledCloseModalButton';
import mutationSendFeedback from 'graphql/mutations/sendFeedback';
import { AppContext } from '../Context';
import useUploadFile from 'components/UploadFile/useUploadFile';
import { SUGGESTION, COMPLIMENT, COMPLAIN } from 'shared/constants/typeReportTicket';
import getErrorMessage from 'shared/helpers/getErrorMessage';

const FeedbackDialog = ({ isOpen, onClose, fullScreen }) => {
  const { actions } = useContext(AppContext);
  const { handleMessage, handleError } = actions;
  const [sendFeedback] = useMutation(mutationSendFeedback);
  const {
    getRootProps,
    getInputProps,
    selectedFiles,
    handleDelete: handleDeleteFile
  } = useUploadFile({
    multiple: true
  });

  const handleSubmit = async ({ type, subject, message }, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      await sendFeedback({
        variables: {
          type,
          subject,
          message,
          attachments: selectedFiles
        }
      });

      const messageSnack = 'Feedback enviado com sucesso';
      handleMessage(messageSnack);
      resetForm();
      onClose();
    } catch (error) {
      handleError(getErrorMessage(error));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ type: SUGGESTION, subject: '', message: '' }}
      validationSchema={Yup.object().shape({
        type: Yup.string().required('Informe a categoria'),
        subject: Yup.string().required('Informe o assunto'),
        message: Yup.string().required('Descreva sua experiência')
      })}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit: handleSubmitForm,
        handleChange,
        isSubmitting,
        errors,
        setFieldValue,
        touched,
        ...rest
      }) => (
        <Dialog
          open={isOpen}
          onClose={onClose}
          aria-labelledby="Enviar um comentário/feedback"
          maxWidth="sm"
          fullScreen={fullScreen}
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Enviar um comentário/feedback</Typography>
            <StyledCloseModalButton onClick={onClose} aria-label="Fechar">
              <Icon>close</Icon>
            </StyledCloseModalButton>
            <DialogContentText>
              Selecione a categoria a qual o seu comentário está relacionado:
            </DialogContentText>
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormControl component="fieldset" error={errors.type}>
                  <RadioGroup
                    aria-label="type"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={SUGGESTION}
                      control={<Radio color="primary" />}
                      label="Sugestão"
                    />
                    <FormControlLabel
                      value={COMPLIMENT}
                      control={<Radio color="primary" />}
                      label="Elogio"
                    />
                    <FormControlLabel
                      value={COMPLAIN}
                      control={<Radio color="primary" />}
                      label="Crítica"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="subject"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  error={errors.subject}
                  helperText={touched.subject && errors.subject}
                  label="Assunto"
                  value={values.subject}
                  onChange={handleChange}
                  data-testid="feedbackSubject"
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="message"
                  onChange={handleChange}
                  error={errors.message}
                  helperText={touched.message && errors.message}
                  multiline
                  rows={3}
                  variant="outlined"
                  value={values.message}
                  data-testid="feedbackMessage"
                  label="Queremos ouvir sua opnião, escreva sua opnião aqui."
                />
              </Grid>
              <Grid item>
                <IconButton variant="outlined" {...getRootProps()}>
                  <AttachFileIcon />
                </IconButton>
                <input {...getInputProps()} />
                {selectedFiles.length > 0 && (
                  <FileList items={selectedFiles} onDelete={handleDeleteFile} />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              data-testid="feedbackSubmit"
              onClick={handleSubmitForm}
            >
              {isSubmitting && (
                <CircularProgress size={20} color="inherit" style={{ marginRight: '12px' }} />
              )}
              <span>Enviar</span>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

FeedbackDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withMobileDialog()(FeedbackDialog);
