import { find } from 'lodash';
import { useMemo, useContext } from 'react';
import { AppContext } from '../Context';

const useBehavior = () => {
  const { getters } = useContext(AppContext);
  const { getInfoUser } = getters;
  const infoUser = getInfoUser();

  const { user = { name: '' }, shippers = [], selected_shipper_id: selectedShipperId } = infoUser;

  const selectedShipper = useMemo(
    () => find(shippers, shipper => shipper.id === selectedShipperId),
    [shippers, selectedShipperId]
  );

  return {
    user,
    selectedShipper
  };
};

export default useBehavior;
