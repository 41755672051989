import { AppContext } from 'components/Context';
import { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export default ({ onDrop, accept, multiple, ...dropZoneConfig }) => {
  const [selectedFiles, setFiles] = useState(() => []);
  const [isLoading, setIsLoading] = useState(false);
  const {
    actions: { handleError }
  } = useContext(AppContext);

  const handleClear = useCallback(() => {
    setFiles([]);
  }, []);

  const handleDrop = useCallback(
    async files => {
      if (!isLoading) {
        setIsLoading(true);
        try {
          const payload = multiple ? files : files[0];
          setFiles(Array.isArray(payload) ? payload : [payload]);
          if (onDrop) {
            await onDrop(payload);
          }
        } catch (error) {
          handleError(error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [isLoading, multiple, onDrop, handleError]
  );

  const handleDelete = useCallback(
    index => {
      const payload = selectedFiles.filter((_, i) => i !== index);
      if (onDrop) {
        onDrop(payload);
      }
      setFiles(payload);
    },
    [onDrop, selectedFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...(dropZoneConfig || {}),
    onDrop: handleDrop,
    accept
  });

  return {
    handleClear,
    handleDrop,
    isLoading,
    handleDelete,
    getRootProps,
    getInputProps,
    isDragActive,
    selectedFiles
  };
};
