import React, { memo, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ErrorOulineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { Wrapper, StyledMessage } from './styled';
import { AppContext } from '../Context';

const MESSAGE_ICONS = {
  Error: <ErrorOulineIcon />,
  Warning: <WarningIcon />,
  Success: <CheckCircleOutlineIcon />,
  Info: <InfoIcon />
};

const AUTO_HIDE_DURATIONS = {
  error: null,
  success: 2000,
  warning: 2000,
  info: 5000
};

const CONNECTION_MESSAGE_PROPS = {
  loseConnection: { message: 'Sem conexão com a internet, verifique seu sinal.', variant: 'error' },
  connectionReestablished: { message: 'Conexão restabelecida', variant: 'success' }
};

const getConnectionMessageProps = ({ loseConnection, connectionReestablished }) => {
  let key;
  if (loseConnection) {
    key = 'loseConnection';
  }
  if (connectionReestablished) {
    key = 'connectionReestablished';
  }
  return key ? CONNECTION_MESSAGE_PROPS[key] : null;
};

const Snackbar = ({ isOffline }) => {
  const [wasOnline, setWasOnline] = useState(true);
  const {
    state: { toast },
    actions: { clearToast }
  } = useContext(AppContext);

  const loseConnection = isOffline;
  const connectionReestablished = !isOffline && !wasOnline;
  const connectionMessageProps = getConnectionMessageProps({
    loseConnection,
    connectionReestablished
  });

  const { message, variant = 'success' } = connectionMessageProps || toast;
  const formatedMessage =
    message &&
    typeof message === 'string' &&
    message.length > 1 &&
    message.replace('GraphQL error:', '');
  const autoHideDuration = AUTO_HIDE_DURATIONS[variant];
  const Icon = MESSAGE_ICONS[variant];

  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      clearToast();
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setWasOnline(!isOffline);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isOffline]);

  return (
    <Wrapper
      aria-describedby="client-snackbar"
      open={Boolean(message && message.length > 0)}
      onClose={handleClose}
      variant={variant}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      autoHideDuration={autoHideDuration}
      message={
        <StyledMessage id="message-id">
          {Icon}
          <div>{formatedMessage}</div>
        </StyledMessage>
      }
      action={
        !autoHideDuration &&
        !isOffline && [
          <CloseIcon key="close" aria-label="Close" color="inherit" onClick={handleClose} />
        ]
      }
    />
  );
};

Snackbar.propTypes = {
  isOffline: PropTypes.bool
};

Snackbar.defaultProps = {
  isOffline: false
};

export default memo(Snackbar);
