import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Badge, Tooltip } from '@material-ui/core';

const BadgeButton = ({ ariaLabel, badgeContent, variant, children, onClick }) => (
  <Tooltip title={ariaLabel} aria-label={ariaLabel}>
    <IconButton color="inherit" onClick={onClick}>
      <Badge
        badgeContent={badgeContent > 99 ? '99+' : badgeContent}
        color="error"
        variant={variant}
      >
        {children}
      </Badge>
    </IconButton>
  </Tooltip>
);

BadgeButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  badgeContent: PropTypes.number,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string
};

BadgeButton.defaultProps = {
  badgeContent: null,
  onClick: () => {},
  variant: undefined
};

export default BadgeButton;
