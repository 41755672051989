import gql from 'graphql-tag';

export default gql`
  mutation reportTicket(
    $type: ReportTicketType!
    $subject: String!
    $message: String!
    $url: String
    $stacktrace: String
    $attachments: [ShipperPanelReportTicketAttachments]
  ) {
    reportTicket(
      type: $type
      subject: $subject
      message: $message
      url: $url
      stacktrace: $stacktrace
      attachments: $attachments
    )
  }
`;
