import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, IconButton, Typography } from '@material-ui/core';
import { Close } from 'mdi-material-ui';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fde56f',
    color: 'rgba(0, 0, 0, 0.6)',
    flexGrow: 1,
    paddingRight: 50,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      paddingTop: 10,
      paddingBottom: 10
    }
  },
  message: {
    flexGrow: 1,
    whiteSpace: 'pre-wrap'
  },
  close: {
    position: 'absolute',
    top: 8,
    right: 20,
    [theme.breakpoints.down('sm')]: {
      right: 12
    }
  }
}));

const HeaderWarning = ({ message, actions, onClose }) => {
  const classes = useStyles();
  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.message} variant="body1">
        {message}
      </Typography>
      {actions}
      <IconButton
        onClick={onClose}
        tabIndex="-1"
        className={classes.close}
        edge="end"
        aria-label="Fechar aviso"
      >
        <Close />
      </IconButton>
    </Toolbar>
  );
};

HeaderWarning.propTypes = {
  message: PropTypes.oneOfType([PropTypes.any]).isRequired,
  actions: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  onClose: PropTypes.func
};

HeaderWarning.defaultProps = {
  actions: null
};

export default HeaderWarning;
