import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, Button, CircularProgress } from '@material-ui/core';

const FormDialogActions = ({
  onClose,
  formikProps,
  initialValues,
  cancelLabel,
  hideClearLabel,
  clearLabel,
  submitLabel
}) => (
  <DialogActions>
    <Button onClick={onClose} color="primary" data-testid="cancelButton">
      {cancelLabel}
    </Button>
    {!hideClearLabel && (
      <Button
        data-testid="clearButton"
        onClick={() => {
          formikProps.handleReset();
          formikProps.setValues(initialValues);
        }}
        color="primary"
      >
        {clearLabel}
      </Button>
    )}
    <Button
      type="submit"
      disabled={formikProps.isSubmitting}
      variant="contained"
      color="primary"
      data-testid="saveButton"
    >
      {formikProps.isSubmitting && <CircularProgress size={20} />}
      <span>{submitLabel}</span>
    </Button>
  </DialogActions>
);

FormDialogActions.propTypes = {
  onClose: PropTypes.func.isRequired,
  formikProps: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  hideClearLabel: PropTypes.bool.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  clearLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired
};

FormDialogActions.defaultProps = {
  initialValues: {}
};

export default memo(FormDialogActions);
