import querystring from 'querystring';

export const stringify = string => querystring.stringify(string);

export default function(search = '') {
  let parsed = {};

  if (search) {
    const stringToParse = search.charAt(0) === '?' ? search.substring(1) : search;
    parsed = querystring.parse(stringToParse);
  }

  return parsed;
}
