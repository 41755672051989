import { Toolbar as MToolbar, InputBase } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  flex-grow: 2;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding: 0 19px;
  box-sizing: border-box;
  height: 40px;
  color: white;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }

  & > div {
    width: 100%;
  }
`;

export const InputDesktop = styled(InputBase)`
  margin: 0 7px;
  color: white !important;
`;

export const InputMobile = styled(InputBase)`
  width: 100%;
`;

export const Toolbar = styled(MToolbar)`
  color: rgba(0, 0, 0, 0.87);
  background-color: #f5f5f5;
  box-shadow: 1px 1px 1px black;
`;
