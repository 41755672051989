import React, { memo, useContext, useState } from 'react';
import { get, sumBy } from 'lodash';
import {
  Button,
  Grid,
  ListItem,
  Typography,
  CircularProgress,
  LinearProgress,
  Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatToBRL } from 'brazilian-values';
import { useMutation } from 'react-apollo';
import { formatToCEP } from 'brazilian-values';
import { AppContext } from 'components/Context';
import { Menu as MUIMenu } from '@material-ui/core';
import MUTATION_REMOVE_FROM_CART from 'graphql/mutations/removeFromCart';
import MUTATION_GENERATE_ORDERS_ON_CART from 'graphql/mutations/generateOrdersOnCart';
import { StyledListItem, StyledIconButton } from './styled';
import { ReactComponent as EmptyCart } from 'assets/your_cart.svg';
import { GREEN, DARK_RED } from 'shared/constants/colors';
import A from 'components/A';

const calculateValues = ({ quotationsOnCart, hasBillingEnabled, conciliation_debts }) => {
  const totalOrders = sumBy(quotationsOnCart, 'total');
  const subTotal = sumBy(quotationsOnCart, item =>
    item.coupon ? item.total + item.coupon.total : item.total
  );
  const discounts = subTotal - totalOrders;
  const addictions = !hasBillingEnabled ? conciliation_debts : 0;
  const total = totalOrders + addictions;
  return {
    subTotal,
    discounts,
    addictions,
    total
  };
};

const ShoppingCartMenu = ({ history, anchorEl, handleCloseMenu }) => {
  const {
    state,
    actions: { handleError, handleMessage, refetchCart }
  } = useContext(AppContext);
  const [removingId, setRemovingId] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [removeFromCart] = useMutation(MUTATION_REMOVE_FROM_CART);
  const [generateOrdersOnCart] = useMutation(MUTATION_GENERATE_ORDERS_ON_CART);

  const { subTotal, discounts, addictions, total } = calculateValues(state);

  const handleRemoveFromCart = async ({ id: quotation_id, code }) => {
    try {
      setRemovingId(quotation_id);
      await removeFromCart({
        variables: {
          quotation_id
        }
      });
      await refetchCart();
      handleMessage(`A cotação #${code} foi removida do carrinho`);
    } catch (error) {
      handleError(error);
    } finally {
      setRemovingId(null);
    }
  };

  const handleCreateOrders = async () => {
    try {
      setIsCreating(true);
      const { data } = await generateOrdersOnCart();
      const invoice_id = get(data, 'generateOrdersOnCart', '');
      handleMessage(`Pedidos criados com sucesso!`);
      history.push(`/financeiro/${invoice_id}`);
    } catch (error) {
      handleError(error);
    } finally {
      setIsCreating(false);
    }
  };

  const renderItem = ({ id, code, recipient, total, coupon }) => {
    const {
      name,
      address: { city, zipcode }
    } = recipient;
    return (
      <StyledListItem>
        <Grid container>
          <Grid item xs>
            <Typography variant="body1">#{code}</Typography>
          </Grid>
          <Grid item>
            {coupon ? (
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography inline variant="caption" style={{ textDecoration: 'line-through' }}>
                    {formatToBRL(total + coupon.total)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography inline variant="body1">
                    {formatToBRL(total)}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography variant="body1">{formatToBRL(total)}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">{name}</Typography>
            <Typography variant="caption">
              {`${city.name}/${city.state} - CEP: ${formatToCEP(zipcode)}`}
            </Typography>
          </Grid>
        </Grid>
        <StyledIconButton
          onClick={() => handleRemoveFromCart({ id, code })}
          disabled={removingId === id}
        >
          {removingId === id ? <CircularProgress size={16} /> : <DeleteIcon />}
        </StyledIconButton>
      </StyledListItem>
    );
  };

  return (
    <MUIMenu
      id="cart-menu"
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      MenuListProps={{
        style: {
          minHeight: '142px',
          width: '320px',
          paddingBottom: 0
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      {state.quotationsOnCart.length === 0 && (
        <ListItem style={{ outline: 'none', padding: '26px 16px' }}>
          <Grid container spacing={2} justfiy="center" alignItems="center">
            <Grid item>
              <EmptyCart width="72" height="72" />
            </Grid>
            <Grid item xs>
              <Typography color="primary" style={{ fontWeight: 500 }} gutterBottom>
                Carrinho
              </Typography>
              <Typography variant="body2">
                Adicione cotações ao carrinho para agrupá-las em uma única fatura.
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      )}
      {state.quotationsOnCart.map(item => renderItem(item))}
      {state.quotationsOnCart.length > 0 && (
        <>
          <ListItem style={{ outline: 'none', padding: '16px' }}>
            <Grid container justify="space-between" alignItems="center">
              <Grid container spacing={2} justify="space-between">
                <Grid item>
                  <Typography variant="body2">Subtotal</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{formatToBRL(subTotal)}</Typography>
                </Grid>
              </Grid>
              {discounts > 0 && (
                <Grid container spacing={2} justify="space-between">
                  <Grid item>
                    <Typography variant="body2" style={{ color: GREEN }}>
                      Desconto
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" style={{ color: GREEN }}>
                      - {formatToBRL(discounts)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {addictions > 0 && (
                <Grid container spacing={2} justify="space-between">
                  <Grid item>
                    <A href="/conciliacoesdefretes" target="_blank" style={{ color: DARK_RED }}>
                      <Tooltip
                        title={`As postagens conferidas geraram uma pêndencia no valor de ${formatToBRL(
                          addictions
                        )} em sua conta. Para concluir o pedido essa pendência precisa ser regulariza e, por conta disso, acrescentamos o valor automaticamente em seu pedido.`}
                      >
                        <Typography variant="body2">Conciliação pendente</Typography>
                      </Tooltip>
                    </A>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" style={{ color: DARK_RED }}>
                      + {formatToBRL(addictions)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2} justify="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="subtitle">Total da fatura</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">{formatToBRL(total)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          <Button
            onClick={handleCreateOrders}
            variant="contained"
            color="primary"
            disabled={isCreating}
            fullWidth
            size="large"
            startIcon={isCreating && <CircularProgress size={16} />}
          >
            Concluir pedido{state.quotationsOnCart.length > 1 ? 's' : ''}
          </Button>
        </>
      )}
      {state.cartLoading && <LinearProgress />}
    </MUIMenu>
  );
};

export default memo(ShoppingCartMenu);
