import React, { useContext, useEffect, useCallback, useState } from 'react';
import { Detector } from 'react-detect-offline';
import Rollbar from 'rollbar';
import { MuiThemeProvider } from '@material-ui/core';
import { IntlProvider, addLocaleData } from 'react-intl';
import pt from 'react-intl/locale-data/pt';
import './App.css';
import checkForUpdates from 'api/checkForUpdates';
import Router from 'router';
import { pushToDataLayer, ROUTE_CHANGE } from 'api/gtm';
import useUtm from 'hooks/useUtm';
import Snackbar from 'components/Snackbar';
import { AppContext } from 'components/Context';
import theme from 'shared/theme';

addLocaleData([...pt]);

const isDevelop = process.env.NODE_ENV === 'development';
const isProduction = process.env.NODE_ENV === 'production';

const rollbarOptions = {
  accessToken: process.env.REACT_APP_ROLLBAR_CODE,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV
  }
};

const App = ({ history, location }) => {
  const { state, actions } = useContext(AppContext);
  const [swVersion, setSwVersion] = useState();
  const toggleHeaderWarning = useCallback(actions.toggleHeaderWarning, []);
  const checkStatusShipper = useCallback(actions.checkStatusShipper, []);

  useUtm(location.search);

  useEffect(() => {
    if (!isDevelop) {
      new Rollbar(rollbarOptions);
    }
  }, []);

  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      pushToDataLayer({ event: ROUTE_CHANGE, page: pathname });
    });

    pushToDataLayer({ event: ROUTE_CHANGE, page: history.location.pathname });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    const shouldVerifyDebits = state.token;
    const ONE_HOUR = 3600000;

    if (shouldVerifyDebits) {
      checkStatusShipper();
    }

    const interval = shouldVerifyDebits && setInterval(() => checkStatusShipper(), ONE_HOUR);

    return () => {
      clearInterval(interval);
    };
  }, [state.token, checkStatusShipper]);

  useEffect(() => {
    const TWO_MINUTES = 120000;

    const interval =
      isProduction &&
      setInterval(() => {
        checkForUpdates({ swVersion, setSwVersion, toggleHeaderWarning });
      }, TWO_MINUTES);

    return () => {
      clearInterval(interval);
    };
  }, [swVersion, toggleHeaderWarning]);

  return (
    <MuiThemeProvider theme={theme}>
      <IntlProvider locale="pt-BR">
        <>
          <Router open={state.isDrawerOpen} onClose={() => actions.toggleDrawer(false)} />
          <Detector
            polling={{ enabled: false }}
            render={({ online }) => <Snackbar isOffline={!online} />}
          />
        </>
      </IntlProvider>
    </MuiThemeProvider>
  );
};

export default App;
