export const CREDITS_INTEGRATION_TYPE_NOT_SUPPORTED = 1;
export const CREDITS_INSUFFICIENT = 2;

// QUOTATION START
export const QUOTATION_ORIGIN_DESTINY_NOT_FOUND = 15;
export const QUOTATION_CARGO_TYPE_REQUIRES_CONTRACT = 20;
export const QUOTATION_MATRIX_LOW_EFFICIENCY_RATE_WARNING = 30;
export const QUOTATION_MATRIX_COLLECT_DELAY_WARNING = 31;
export const QUOTATION_REVERSE_COLLECT_WARNING = 32;

// QUOTATION END

// AUTH EXCEPTIONS START
export const AUTH_USER_NOT_FOUND = 100;
export const AUTH_USER_UNVERIFIED_EMAIL = 101;
export const AUTH_INVALID_TOKEN = 102;
export const AUTH_USER_SHIPPER_NOT_FOUND = 110;
export const AUTH_USER_SHIPPER_NOT_RELATED = 111;
export const AUTH_USER_SHIPPING_CARRIER_NOT_FOUND = 120;
export const AUTH_USER_SHIPPING_CARRIER_CONTACT_NOT_FOUND = 121;
export const AUTH_USER_ADMIN_NOT_FOUND = 131;
export const AUTH_USER_ADMIN_INACTIVE = 132;
export const AUTH_USER_ADMIN_OLD_PASSWORD = 133;
export const AUTH_USER_PASSWORD_INVALID = 134;
// AUTH EXCEPTIONS END

// JADLOG START
export const JADLOG_CREATE_ORDER_ERROR = 10000;
export const JADLOG_TRACKING_RETRIEVE_ERROR = 10100;
// JADLOG END

// AZUL START
export const AZUL_CREATE_ORDER_ERROR = 11000;
export const AZUL_TRACKING_RETRIEVE_ERROR = 11100;
// AZUL END

// AZUL START
export const CLICKSIGN_CONTRACT_DOESNT_EXISTS_ERROR = 12000;
export const CLICKSIGN_RESPONSIBLE_INVALID_ERROR = 12100;
export const CLICKSIGN_SHIPPER_ATTRIBUTE_ERROR = 12200;
export const CLICKSIGN_CONTRACT_GENERATED_ERROR = 12300;
// AZUL END

// JUNO START
export const JUNO_CREATE_CHARGE_ERROR = 20000;
export const JUNO_CANCEL_CHARGE_ERROR = 20100;
export const JUNO_CREATE_PAYMENT_ERROR = 20200;
export const JUNO_CANCEL_PAYMENT_ERROR = 20300;
export const JUNO_TOKENIZE_CARD_ERROR = 20400;
export const JUNO_TOKENIZE_CARD_NOT_FOUND_ERROR = 20401;
// JUNO END

// PAGSEGURO START
export const PAGSEGURO_CREATE_CHECKOUT_ERROR = 30000;
export const PAGSEGURO_GET_PAYMENT_ERROR = 30100;
export const PAGSEGURO_SEARCH_PAYMENTS_ERROR = 30110;
// PAGSEGURO END

// MERCADOPAGO START
export const MERCADOPAGO_CREATE_CHECKOUT_ERROR = 40000;
export const MERCADOPAGO_GET_PAYMENT_ERROR = 40100;
export const MERCADOPAGO_SEARCH_PAYMENTS_ERROR = 40110;
// MERCADOPAGO END

// PAGHIPER START
export const PAGHIPER_CREATE_BILLET_ERROR = 50000;
export const PAGHIPER_GET_PAYMENT_ERROR = 50100;
export const PAGHIPER_SEARCH_PAYMENTS_ERROR = 50110;
// PAGHIPER END

// POLL START
export const POLL_NOT_FOUND = 60001;
export const POLL_CANNOT_VOTE = 60002;
// POLL END

// SALESORDER START
export const SALESORDER_PRICE_WARNING = 70001;
// SALESORDER END

// EXTERNALTOKEN START
export const EXTERNALTOKEN_NOT_FOUND = 80001;
// EXTERNALTOKEN END
