import { useState } from 'react';

const useMenu = () => {
  const [anchorEl, dispatch] = useState();

  const handleOpenMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(event.currentTarget);
  };
  const handleCloseMenu = () => dispatch(null);

  return {
    anchorEl,
    handleOpenMenu,
    handleCloseMenu
  };
};

export default useMenu;
