import styled from 'styled-components';

export const Wrapper = styled.footer`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: #ccc;

  @media print {
    display: none;
  }
`;

export const Image = styled.img`
  width: 120px;
  height: 80px;
  object-fit: contain;
  filter: sepia(1) grayscale(0.4) hue-rotate(197deg) opacity(0.6);
`;
