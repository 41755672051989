import gql from 'graphql-tag';

export default gql`
  query {
    checkStatusShipper {
      shipper_id
      group_id
      status
      message
      zipcode
      address
      on_fit_rule
      quotations_credit_left
      answered_fit_rule_form
      has_plan
      has_cart
      has_sales_order
      has_refunds
      has_renegotiations
      has_billing_enabled
      has_conciliations
      cart_count
      ticket_count_awaiting_response
      ticket_count_awaiting_analyses
      balance
      conciliation_credits
      conciliation_debts
      email_verified_at
      ignore_email_verification
      default_cargo_types {
        id
        name
      }
      invoices {
        id
        expire_at
        expire_text
        total
      }
    }
  }
`;
