import React, { memo, useContext } from 'react';
import { formatToBRL } from 'brazilian-values';
import { AppContext } from 'components/Context';
import ScoreItem from '../AppBarScoreItem';
import { Wrapper } from './styled';

const AppBarScore = ({ history }) => {
  const { state } = useContext(AppContext);
  const openBalance = () => history.push('/carteira');
  return (
    <Wrapper>
      <ScoreItem label="Carteira" onClick={openBalance}>
        {state.balance != null ? formatToBRL(state.balance) : '...'}
      </ScoreItem>
    </Wrapper>
  );
};

export default memo(AppBarScore);
