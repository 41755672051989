import React, { memo, useContext } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Route as RRoute, Redirect } from 'react-router-dom';
import AppBar from 'components/AppBar';
import { AppContext } from 'components/Context';
import Wrapper from './styled';

const getPageTitle = ({ title, params }) => (typeof title === 'function' ? title(params) : title);
const getReturnPage = ({ url, params }) => (typeof url === 'function' ? url(params) : url);

const Route = ({
  hideAppBar,
  pageReturn,
  showSearch,
  searchPlaceholder,
  title,
  path,
  component: Component,
  isPrivate,
  tabs
}) => {
  const { state } = useContext(AppContext);
  const { headerWarning, token } = state;
  const isShowingWarning = headerWarning.message;

  return (
    <RRoute
      exact
      path={path}
      render={props => {
        if (!Component) {
          throw new Error(
            `Please define a component for ${title}(${path}) in src\router\routes.js`
          );
        }
        const params = {
          ...get(props, 'location.state.params', {}),
          ...get(props, 'match.params', {})
        };
        const pageTitle = getPageTitle({ params, title });
        const needToShowAppBar = !hideAppBar && isPrivate;
        const returnPageUrl = getReturnPage({ url: pageReturn, params });

        return (isPrivate && token) || !isPrivate ? (
          <>
            {needToShowAppBar && (
              <AppBar
                {...props}
                returnPage={returnPageUrl}
                showSearch={showSearch}
                searchPlaceholder={searchPlaceholder}
                pageTitle={pageTitle}
                tabs={tabs}
              />
            )}
            <Wrapper showappbar={needToShowAppBar ? 1 : 0} showwarning={isShowingWarning ? 1 : 0}>
              <Component {...props} />
            </Wrapper>
          </>
        ) : (
          <Redirect to={{ pathname: '/login', state: { referrer: props.location } }} />
        );
      }}
    />
  );
};
Route.propTypes = {
  hideAppBar: PropTypes.bool,
  pageReturn: PropTypes.string,
  showSearch: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  path: PropTypes.string.isRequired,
  component: PropTypes.any.isRequired,
  isPrivate: PropTypes.bool,
  searchPlaceholder: PropTypes.string
};

Route.defaultProps = {
  hideAppBar: false,
  pageReturn: '',
  showSearch: false,
  title: '',
  isPrivate: false,
  searchPlaceholder: undefined
};

export default memo(Route);
