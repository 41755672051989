import { createMuiTheme } from '@material-ui/core';
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TERTIARY_COLOR,
  QUARTENARY_COLOR
} from 'shared/constants/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    secondary: {
      main: SECONDARY_COLOR
    }
  },
  typography: {
    h1: {
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '4.2rem'
      }
    },
    h2: {
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '2.625rem'
      }
    },
    h3: {
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '2.1rem'
      }
    },
    h4: {
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '1.4875rem'
      }
    },
    h5: {
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '1.25rem'
      }
    },
    h6: {
      fontWeight: 500,
      '@media (max-width:600px)': {
        fontSize: '1.1rem',
        lineHeight: '1.35'
      }
    },
    body1: {
      fontWeight: 400
    },
    body2: {
      fontWeight: 400
    },
    subtitle1: {
      fontWeight: 400
    },
    subtitle2: {
      fontWeight: 400
    },
    caption: {
      lineHeight: '1.375em',
      display: 'block'
    }
  },
  overrides: {
    MuiRadio: {
      root: {
        padding: '3px 12px'
      }
    },
    MuiMenuItem: {
      root: {
        paddingTop: '11px',
        paddingBottom: '11px'
      }
    },
    MuiCardHeader: {
      root: {
        textAlign: 'left'
      }
    },
    MuiSpeedDial: {
      root: {
        position: 'fixed',
        bottom: 16,
        right: 16
      }
    },
    MuiSpeedDialAction: {
      staticTooltipLabel: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'white',
        padding: '4px 8px',
        fontSize: '0.8rem',
        whiteSpace: 'nowrap'
      }
    }
  }
});

export const secondaryTheme = createMuiTheme({
  palette: {
    primary: {
      main: TERTIARY_COLOR
    },
    secondary: {
      main: QUARTENARY_COLOR
    }
  }
});

export default theme;
