import styled from 'styled-components';
import { IconButton, ListItem } from '@material-ui/core';

export const StyledListItem = styled(ListItem)`
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute !important;
  right: 0;
  bottom: 0;
`;
