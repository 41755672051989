import gql from 'graphql-tag';

export default gql`
  query fetchQuotationsOnCart {
    fetchQuotationsOnCart {
      id
      code
      total
      coupon {
        total
      }
      recipient {
        id
        name
        document
        address {
          id
          zipcode
          street
          number
          complement
          district
          city {
            id
            name
            state
          }
        }
      }
    }
  }
`;
