import styled from 'styled-components';
import { Avatar as MuiAvatar } from '@material-ui/core';

export const Toolbar = styled.div`
  background: rgba(69, 62, 151, 0.68);
  padding: 20px;
  line-height: 20px;

  h1,
  h2 {
    color: white;
  }

  h1 {
    font-size: 16px;
    font-weight: 200;
    margin-top: 12px;
  }

  h2 {
    font-size: 12px;
    font-weight: 200;
  }
`;

export const Avatar = styled(MuiAvatar)`
  cursor: pointer !important;
  width: 65px !important;
  height: 65px !important;
  margin-bottom: 20px !important;
  background-color: #bdbdbd80 !important;

  img {
    margin-top: 0;
  }
`;

export const Logo = styled.img`
  object-fit: contain;
  margin: 0 10px;
  height: 40px;
  max-width: 120px;
`;
