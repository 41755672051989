import React from 'react';
import Button from '@material-ui/core/Button';

const requestUpdateVersion = ({ code, swVersion, setSwVersion, toggleHeaderWarning }) => {
  let payload = {
    status: 'update',
    message: (
      <>
        <strong>Atualização disponível:</strong>
        {` Recarregue a página para obter as últimas atualizações da plataforma.`}
      </>
    ),
    actions: <Button onClick={() => window.location.reload(true)}>Recarregar</Button>
  };

  if (swVersion && swVersion !== code) {
    toggleHeaderWarning(payload);
  }

  setSwVersion(code);
};

const checkForUpdates = ({ swVersion, setSwVersion, toggleHeaderWarning }) => {
  try {
    return fetch('/asset-manifest.json')
      .then(response => response.json())
      .then(({ files }) => {
        const manifest = Object.keys(files).find(key => key.includes('precache-manifest')) || '';
        const code = manifest.replace('precache-manifest.', '').replace('.js', '');
        if (code) {
          requestUpdateVersion({ code, swVersion, setSwVersion, toggleHeaderWarning });
        }
      })
      .catch(e => {
        console.error('Ocorreu um erro na checagem por atualizações: ' + e.message);
      });
  } catch (error) {
    console.log(error);
  }
};
export default checkForUpdates;
