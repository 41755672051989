import React, { memo } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { MenuItem } from '@material-ui/core';
import { Avatar, BusinessName, MenuButton } from './styled';
import useBehavior from './useBehavior';
import Menu from 'components/Menu';
import { MenuDividerText } from 'components/Menu/styled';
import useMenu from 'hooks/useMenu';
import NewShipperModal from '../NewShipperModal';
import useDialog from 'hooks/useDialog';
import { logout } from 'api/user';
import AppBarScore from 'components/AppBar/AppBarScore';

const ProfileMenu = ({ history }) => {
  const { isOpen, handleOpen, handleClose } = useDialog(false);
  const {
    infoUser,
    state,
    user,
    shippers,
    selectedShipper,
    toggleUseLetter,
    handleChangeShipper
  } = useBehavior();

  const { anchorEl, handleOpenMenu, handleCloseMenu } = useMenu();
  const letter = selectedShipper.name.charAt(0).toUpperCase();
  const { avatar } = user;

  const handleOpenMyData = () => history.push('/configuracoes/identificacao');

  const menuDefaultOptions = [
    { text: 'Nova empresa', icon: 'add', onClick: handleOpen },
    { text: 'Meus dados', icon: 'account_box_outline', onClick: handleOpenMyData },
    { text: 'Sair', icon: 'exit_to_app', onClick: logout }
  ];

  return (
    infoUser && (
      <>
        <MenuButton aria-controls="profile-menu" aria-haspopup="true" onClick={handleOpenMenu}>
          {state.useLetter || !avatar ? (
            <Avatar useletter="true">{letter}</Avatar>
          ) : (
            <Avatar alt={letter} src={avatar} imgProps={{ onError: () => toggleUseLetter(true) }}>
              {letter}
            </Avatar>
          )}
          <BusinessName>{selectedShipper.name}</BusinessName>
          <ArrowDropDownIcon />
        </MenuButton>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          handleClose={handleCloseMenu}
          items={menuDefaultOptions}
        >
          <AppBarScore history={history} />
          <MenuDividerText>Minhas empresas</MenuDividerText>
          {shippers.map(shipper => (
            <MenuItem
              disabled={shipper.id === selectedShipper.id}
              key={shipper.id}
              onClick={() => handleChangeShipper(shipper.id)}
            >
              {shipper.name}
            </MenuItem>
          ))}
        </Menu>

        <NewShipperModal open={isOpen} onClose={handleClose} />
      </>
    )
  );
};

export default memo(ProfileMenu);
