import styled, { css } from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
  padding: 1rem;
  ${({ noPadding }) => {
    if (noPadding) {
      return css`
        padding: 0;
      `;
    }
  }}
`;

export const Image = styled.img`
  width: 120px;
  height: 80px;
  object-fit: contain;
`;
