import React, { PureComponent } from 'react';
import {
  FormControl,
  TextField,
  Grid,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import client from 'api';
import { CnpjFormat } from '../Format';
import mutationCreateShipper from 'graphql/mutations/createShipper';
import { withAppContext } from '../Context';
import { requiredValidation, cnpjValidation } from 'shared/helpers/validations';
import FormDialog from '../FormDialog';
import A from 'components/A';

const validationSchema = Yup.object().shape({
  document: requiredValidation(cnpjValidation),
  company_name: requiredValidation(Yup.string()),
  terms: Yup.bool()
    .oneOf([true], 'Para se cadastrar é necessário aceitar os termos e condições')
    .required('Para se cadastrar é necessário aceitar os termos e condições')
});

class FormAdd extends PureComponent {
  handleUpdate = (values, { resetForm }) => {
    const { actions } = this.props.appContext;
    const { setToken } = actions;

    return client
      .mutate({
        mutation: mutationCreateShipper,
        variables: {
          document: values.document,
          name: values.company_name
        }
      })
      .then(({ data }) => {
        resetForm();
        setToken(data.createShipper.token);
        window.location.reload();
      });
  };

  render() {
    return (
      <FormDialog
        open={this.props.open}
        onClose={this.props.onClose}
        initialValues={{
          document: '',
          company_name: '',
          terms: ''
        }}
        validationSchema={validationSchema}
        onSubmit={this.handleUpdate}
        title="Criar nova empresa"
        render={({ values, handleChange, errors, touched }) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  name="document"
                  label="CNPJ"
                  type="text"
                  onChange={handleChange}
                  error={touched.document && errors.document}
                  helperText={touched.document && errors.document}
                  value={values.document}
                  InputLabelProps={{ shrink: values.document }}
                  InputProps={{
                    inputComponent: CnpjFormat
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  name="company_name"
                  label="Nome Fantasia"
                  type="text"
                  onChange={handleChange}
                  error={touched.company_name && errors.company_name}
                  helperText={touched.company_name && errors.company_name}
                  value={values.company_name}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth error={Boolean(touched.terms && errors.terms)}>
                <FormControlLabel
                  checked={values.terms}
                  control={<Checkbox color="primary" />}
                  label={
                    <Typography variant="caption">
                      Concordo com os&nbsp;
                      <A href={`${process.env.REACT_APP_WEBSITE}/termos`} target="_blank">
                        termos e condições
                      </A>
                      &nbsp;da Central do Frete
                    </Typography>
                  }
                  onClick={handleChange}
                  name="terms"
                />
                {touched.terms && errors.terms && <FormHelperText>{errors.terms}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
        )}
      />
    );
  }
}

export default withRouter(withAppContext(FormAdd));
