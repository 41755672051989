import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const StyledCloseModalButton = styled(IconButton)`
  position: absolute !important;
  top: 12px;
  right: 12px;
`;

export default StyledCloseModalButton;
