import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Label, Value, Wrapper } from './styled';

const ScoreItem = ({ children, label, onClick }) => (
  <Wrapper onClick={onClick}>
    <Label>{label}</Label>
    <Value>{children}</Value>
  </Wrapper>
);

ScoreItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func
};

ScoreItem.defaultProps = {
  onClick: null
};

export default memo(ScoreItem);
