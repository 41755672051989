import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 0.85rem;
  background: #342f73;
  margin: -8px 0 0;
  cursor: pointer;
`;
