import styled from 'styled-components';
import { Button } from '@material-ui/core';

export default styled(Button)`
  border-radius: 0 !important;
  box-shadow: none !important;
  font-size: 18px;
  line-height: 20px;

  svg {
    margin-left: 7px !important;
    width: 10px !important;
    height: 12px !important;
  }
`;
