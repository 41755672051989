import React from 'react';
import { Hidden } from '@material-ui/core';
import LazyWithRetry from 'components/LazyWithRetry';

const IdentificationPage = LazyWithRetry(() => import('../pages/SettingsPageIdentification'));
const EmployeesPage = LazyWithRetry(() => import('../pages/SettingsPageEmployees'));
const WithdrawalTimesPage = LazyWithRetry(() => import('../pages/SettingsPageWithdrawalTimes'));
const CargoTypePage = LazyWithRetry(() => import('../pages/SettingsPageCargoType'));
const NotificationsPage = LazyWithRetry(() => import('../pages/SettingsPageNotifications'));
const InvoiceListPage = LazyWithRetry(() => import('../pages/InvoiceListPage'));
const RefundsListPage = LazyWithRetry(() => import('../pages/RefundsListPage'));
const InvoiceDetailPage = LazyWithRetry(() => import('../pages/InvoiceDetailPage'));
const FollowCollectsPage = LazyWithRetry(() => import('../pages/FollowCollectsPage'));
const FollowDeliveriesPage = LazyWithRetry(() => import('../pages/FollowDeliveriesPage'));
const OrderListPage = LazyWithRetry(() => import('../pages/OrderListPage'));
const OrderDetailPage = LazyWithRetry(() => import('../pages/OrderDetailPage'));
const QuotationListPage = LazyWithRetry(() => import('../pages/QuotationListPage'));
const CreateQuotationPage = LazyWithRetry(() => import('../pages/CreateQuotationPage'));
const NewQuotationDetailPage = LazyWithRetry(() => import('../pages/NewQuotationDetailPage'));
const MultipleQuotationsPage = LazyWithRetry(() => import('../pages/MultipleQuotationsPage'));
const LoginPage = LazyWithRetry(() => import('../pages/LoginPage'));
const SignupPage = LazyWithRetry(() => import('../pages/SignupPage'));
const ValidateAccountPage = LazyWithRetry(() => import('../pages/ValidateAccountPage'));
const ConfirmAccountPage = LazyWithRetry(() => import('../pages/ConfirmAccountPage'));
const ForgetPasswordPage = LazyWithRetry(() => import('../pages/ForgetPasswordPage'));
const RecoveryPassword = LazyWithRetry(() => import('../pages/RecoveryPassword'));
const IntegrationApiPage = LazyWithRetry(() => import('../pages/IntegrationApiPage'));
const IntegrationSettingsPage = LazyWithRetry(() => import('../pages/IntegrationSettingsPage'));
const OrdersSalesListPage = LazyWithRetry(() => import('../pages/OrdersSalesListPage'));
const ScoreLanding = LazyWithRetry(() => import('../pages/ScoreLanding'));
const IndicationsLanding = LazyWithRetry(() => import('../pages/IndicationsLanding'));
const ScoreStatement = LazyWithRetry(() => import('../pages/ScoreStatement'));
const LabelListPage = LazyWithRetry(() => import('../pages/LabelListPage'));
const EmitLabelsPage = LazyWithRetry(() => import('../pages/EmitLabelsPage'));
const PrintLabelsPage = LazyWithRetry(() => import('../pages/PrintLabelsPage'));
const OrderProblemPage = LazyWithRetry(() => import('../pages/OrderProblemPage'));
const FitRuleFormPage = LazyWithRetry(() => import('../pages/FitRuleFormPage'));
const WalletPage = LazyWithRetry(() => import('../pages/WalletPage'));
const WalletBuyCreditsPage = LazyWithRetry(() => import('../pages/WalletBuyCreditsPage'));
const WalletThankYouPage = LazyWithRetry(() => import('../pages/WalletThankYouPage'));
const WalletInvoiceListPage = LazyWithRetry(() => import('../pages/WalletInvoiceListPage'));
const WalletInvoiceDetailPage = LazyWithRetry(() => import('../pages/WalletInvoiceDetailPage'));
const PlansPage = LazyWithRetry(() => import('../pages/PlansPage'));
const ContractPage = LazyWithRetry(() => import('../pages/ContractPage'));
const ContractSignPage = LazyWithRetry(() => import('../pages/ContractSignPage'));
const HirePlanPage = LazyWithRetry(() => import('../pages/HirePlanPage'));
const HirePlanThankYouPage = LazyWithRetry(() => import('../pages/HirePlanThankYouPage'));
const SubscriptionPage = LazyWithRetry(() => import('../pages/SubscriptionPage'));
const SubscriptionInvoicePage = LazyWithRetry(() => import('../pages/SubscriptionInvoicePage'));
const SubscriptionChangePaymentPage = LazyWithRetry(() =>
  import('../pages/SubscriptionChangePaymentPage')
);
const OrderExemptionTerm = LazyWithRetry(() => import('../pages/OrderExemptionTerm'));
const TicketsList = LazyWithRetry(() => import('../pages/TicketsList'));
const TicketDetailPage = LazyWithRetry(() => import('../pages/TicketDetailPage'));
const DashboardPage = LazyWithRetry(() => import('../pages/DashboardPage'));
const ProductListPage = LazyWithRetry(() => import('../pages/ProductListPage'));
const PackageListPage = LazyWithRetry(() => import('../pages/PackageListPage'));
const RenegotiationsListPage = LazyWithRetry(() => import('../pages/RenegotiationsListPage'));
const RenegotiationDetailPage = LazyWithRetry(() => import('../pages/RenegotiationDetailPage'));
const RenegotiationInstallmentDetailPage = LazyWithRetry(() =>
  import('../pages/RenegotiationInstallmentDetailPage')
);
const ConciliationListPage = LazyWithRetry(() => import('../pages/ConciliationListPage'));

/**
 * #Route Types
 *
 * Private: only for logged user
 * Public: every route that need no auth/token
 *
 * #Props list
 *
 * path String: url path
 * component Component: Page component to render
 * hideAppBar Boolean: when its a page that need to hide the app bar
 * pageReturn String: url that will be used in appbar backbutton
 * title String: Page title
 * showSearch: Render AppbarSearch (publicRoutes will hide it automatically)
 */

export const publicRoutes = [
  { path: '/login', component: LoginPage },
  { path: '/cadastro', component: SignupPage },
  { path: '/esqueceu-sua-senha', component: ForgetPasswordPage },
  { path: '/recuperar-senha/:remember_token', component: RecoveryPassword },
  { path: '/validar-email', component: ValidateAccountPage }
];

export const privateRoutes = [
  { title: 'Etiquetas', path: '/etiquetas', component: LabelListPage },
  {
    title: 'Confirme seu e-mail',
    path: '/confirmar-email',
    component: ConfirmAccountPage,
    hideAppBar: true
  },
  {
    title: 'Imprimir etiquetas',
    pageReturn: '/etiquetas',
    path: '/emitir-etiquetas/:id/impressao',
    component: PrintLabelsPage
  },
  {
    showSearch: true,
    title: 'Emitir etiquetas',
    pageReturn: '/etiquetas',
    path: '/emitir-etiquetas',
    component: EmitLabelsPage
  },
  { title: 'Central de Pontos', path: '/central-de-pontos', component: ScoreLanding },
  { title: 'Indique e Ganhe', path: '/indique-e-ganhe', component: IndicationsLanding },
  {
    title: 'Meus dados',
    path: '/configuracoes/identificacao',
    component: IdentificationPage
  },
  {
    showSearch: true,
    title: 'Colaboradores',
    path: '/configuracoes/colaboradores',
    component: EmployeesPage
  },
  {
    title: 'Horários de coleta',
    path: '/configuracoes/horarios',
    component: WithdrawalTimesPage
  },
  {
    title: 'Tipo de carga',
    path: '/configuracoes/tipodecarga',
    component: CargoTypePage
  },
  {
    title: 'Notificações',
    path: '/configuracoes/notificacoes',
    component: NotificationsPage
  },
  {
    title: ({ code }) => `Fatura #${code.toString().padStart(6, '0')}`,
    pageReturn: '/financeiro',
    path: '/financeiro/:code',
    component: InvoiceDetailPage
  },
  {
    showSearch: true,
    title: 'Financeiro',
    path: '/financeiro',
    component: InvoiceListPage
  },
  {
    showSearch: true,
    title: 'Conciliações de Fretes',
    path: '/conciliacoesdefretes/:tab?',
    component: ConciliationListPage,
    tabs: [
      {
        label: 'Em conciliação',
        to: '/conciliacoesdefretes/emconciliacao'
      },
      {
        label: 'Conciliados',
        to: '/conciliacoesdefretes/conciliados'
      }
    ]
  },
  {
    showSearch: true,
    title: 'Reembolsos',
    path: '/reembolsos',
    component: RefundsListPage
  },
  {
    pageReturn: ({ renegotiationId }) => `/renegociacoes/${renegotiationId}`,
    title: ({ renegotiationId }) =>
      `Parcela da Renegociação #${renegotiationId.toString().padStart(6, '0')}`,
    path: '/renegociacoes/:renegotiationId/parcela/:installmentId',
    component: RenegotiationInstallmentDetailPage
  },
  {
    pageReturn: () => `/renegociacoes`,
    title: ({ id }) => `Renegociação #${id.toString().padStart(6, '0')}`,
    path: '/renegociacoes/:id',
    component: RenegotiationDetailPage
  },
  {
    showSearch: true,
    title: 'Renegociações',
    path: '/renegociacoes',
    component: RenegotiationsListPage
  },
  {
    title: ({ code }) => `Pedido #${code}`,
    path: '/pedido/:code/assinar-termo-de-isencao',
    pageReturn: ({ code }) => `/pedido/${code}`,
    component: OrderExemptionTerm
  },
  {
    title: ({ code }) => `Pedido #${code}`,
    path: '/pedido/:code/tenho-um-problema',
    pageReturn: ({ code }) => `/pedido/${code}`,
    component: OrderProblemPage
  },
  {
    pageReturn: '/pedidos',
    title: ({ code }) => `Pedido #${code}`,
    path: '/pedido/:code',
    component: OrderDetailPage
  },
  {
    pageReturn: '/pedidos',
    title: ({ code }) => `Pedido #${code}`,
    path: '/pedidoconcluido/:code',
    component: OrderDetailPage
  },
  {
    showSearch: true,
    searchPlaceholder: 'Busque por código do Pedido ou Nota Fiscal',
    title: 'Acompanhar coletas',
    path: '/acompanharpedidos/:tab?',
    component: FollowCollectsPage
  },
  {
    showSearch: true,
    searchPlaceholder: 'Busque por código do Pedido ou Nota Fiscal',
    title: 'Acompanhar coletas',
    path: '/acompanharcoletas',
    component: FollowCollectsPage
  },
  {
    showSearch: true,
    searchPlaceholder: 'Busque por código do Pedido ou Nota Fiscal',
    title: 'Acompanhar entregas',
    path: '/acompanharentregas',
    component: FollowDeliveriesPage
  },
  {
    showSearch: true,
    title: 'Pedidos',
    path: '/pedidos',
    component: OrderListPage
  },
  {
    pageReturn: '/cotacoes',
    title: ({ code }) => (
      <>
        <Hidden xsDown>Cotação </Hidden>#{code}
      </>
    ),
    path: '/cotacao/:code/:tab?',
    component: NewQuotationDetailPage
  },
  {
    pageReturn: '/cotacoes',
    title: ({ code }) => (
      <>
        <Hidden xsDown>Cotação </Hidden>#{code}
      </>
    ),
    path: '/nova_cotacao/:code/:tab?',
    component: NewQuotationDetailPage
  },
  {
    showSearch: true,
    title: 'Cotações Realizadas',
    path: '/cotacoes',
    component: QuotationListPage
  },
  {
    pageReturn: '/cotar',
    title: 'Cotação em lote',
    path: '/cotacao_em_lote',
    component: MultipleQuotationsPage
  },
  { title: 'Extrato', path: '/extrato-de-pontos', component: ScoreStatement },
  {
    title: 'Vendas - Fila de Contratação',
    path: '/vendas',
    showSearch: true,
    component: OrdersSalesListPage
  },
  {
    pageReturn: '/integracoes',
    title: 'Configurações da Integração',
    path: '/integracoes/configuracoes',
    component: IntegrationSettingsPage
  },
  { title: 'Integrações', path: '/integracoes', component: IntegrationApiPage },
  { title: 'Integrações', path: '/api', component: IntegrationApiPage },
  { title: 'Ganhe 15 cotações', path: '/regrafit', component: FitRuleFormPage },
  { title: 'Planos', path: '/planos', component: PlansPage },
  {
    title: 'Assinatura',
    path: '/assinatura/:tab?',
    component: SubscriptionPage,
    tabs: [
      {
        label: 'Faturas',
        to: '/assinatura/faturas'
      },
      {
        label: 'Meu Plano',
        to: '/assinatura/meuplano'
      }
    ]
  },
  {
    pageReturn: ({ pageReturn }) => pageReturn || '/assinatura/meuplano',
    title: 'Atualizar forma de pagamento',
    path: '/assinatura-atualizar-forma-de-pagamento',
    component: SubscriptionChangePaymentPage
  },
  {
    title: ({ code }) => `Fatura #${code.toString().padStart(6, '0')}`,
    pageReturn: '/assinatura',
    path: '/assinatura-faturas/:code',
    component: SubscriptionInvoicePage
  },
  {
    path: '/contrato/assinar',
    component: ContractSignPage,
    title: 'Assinatura do Contrato',
    pageReturn: '/contrato'
  },
  {
    path: '/contrato',
    component: ContractPage,
    title: 'Porque assinar?',
    pageReturn: '/'
  },
  {
    path: '/contratar-plano',
    component: HirePlanPage,
    title: 'Contratar Plano',
    pageReturn: '/planos'
  },
  { title: 'Obrigado!', path: '/contratar-plano-obrigado', component: HirePlanThankYouPage },
  {
    title: 'Central de Atendimento',
    path: '/central-de-atendimento/:tab?',
    showSearch: true,
    component: TicketsList,
    tabs: [
      {
        label: 'Aguardando sua resposta',
        to: '/central-de-atendimento/aguardandosuaresposta',
        count: state => state.ticketCountAwaitingResponse
      },
      {
        label: 'Em análise',
        to: '/central-de-atendimento/emanalise',
        count: state => state.ticketCountAwaitingAnalyses
      },
      {
        label: 'Atendimentos encerrados',
        to: '/central-de-atendimento/encerrados'
      }
    ]
  },
  {
    title: 'Central de Atendimento',
    path: '/central-de-atendimento',
    showSearch: true,
    component: TicketsList
  },
  {
    title: ({ code }) => `Fatura #${code.toString().padStart(6, '0')}`,
    path: '/carteira/recargas/:code',
    showSearch: false,
    component: WalletInvoiceDetailPage,
    pageReturn: '/carteira'
  },
  {
    title: 'Recargas',
    path: '/carteira/recargas',
    showSearch: false,
    component: WalletInvoiceListPage,
    pageReturn: '/carteira'
  },
  {
    title: 'Inserir saldo',
    path: '/carteira/inserirsaldo',
    showSearch: false,
    component: WalletBuyCreditsPage,
    pageReturn: '/carteira'
  },
  {
    title: 'Inserir saldo',
    path: '/carteira/obrigado',
    component: WalletThankYouPage
  },
  {
    title: 'Carteira',
    path: '/carteira',
    showSearch: true,
    component: WalletPage
  },
  {
    title: 'Produtos',
    path: '/produtos',
    showSearch: true,
    component: ProductListPage
  },
  {
    title: 'Pacotes',
    path: '/pacotes',
    showSearch: true,
    component: PackageListPage
  },
  {
    title: ({ id }) => `#${id}`,
    path: '/ticket/:id',
    component: TicketDetailPage,
    pageReturn: ({ pageReturn }) => pageReturn || '/central-de-atendimento'
  },
  { title: 'Nova Cotação', path: '/cotar', component: CreateQuotationPage },
  { title: 'Dashboard', path: '/dashboard', component: DashboardPage },
  { title: 'Nova Cotação', path: '/', component: CreateQuotationPage }
];
