import { reduce } from 'lodash';
import { useState, useEffect } from 'react';
import querystring from 'querystring';

const UTMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

const useUtm = (search = '') => {
  const [utm, setUtm] = useState({});

  useEffect(() => {
    const sessionUtm = window.sessionStorage.getItem('utm');

    if (sessionUtm) {
      setUtm(JSON.parse(sessionUtm));
    } else if (search) {
      const searchParsed = querystring.parse(
        search.charAt(0) === '?' ? search.substring(1) : search
      );
      const utmQueryParams = reduce(
        Object.keys(searchParsed),
        (acc, key) => ({
          ...acc,
          ...(UTMS.indexOf(key) !== -1 ? { [key]: searchParsed[key] } : {})
        }),
        {}
      );
      if (Object.keys(utmQueryParams).length > 0) {
        sessionStorage.setItem('utm', JSON.stringify(utmQueryParams));
        setUtm(utmQueryParams);
      }
    }
  }, [search]);

  return utm;
};

export default useUtm;
