import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import './index.css';
import App from './App';
import Provider from 'components/Context';
import { unregister } from './registerServiceWorker';
import client from 'api';
import ErrorBoundary from 'api/errorReport';

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider>
      <BrowserRouter>
        <ErrorBoundary>
          <Route component={App} path="/" />
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);
unregister();
