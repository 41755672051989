import React, { useContext, useEffect, useCallback } from 'react';
import { AppContext } from 'components/Context';

const Chat = ({ isLoggedIn }) => {
  const { getters } = useContext(AppContext);
  const memoizedGetUserInfo = useCallback(getters.getInfoUser, [isLoggedIn]);

  useEffect(() => {
    let interval;
    const handleChat = async () => {
      if (window.zE) {
        if (isLoggedIn) {
          const userInfo = await memoizedGetUserInfo();
          window.zE('webWidget', 'show');
          if (userInfo && userInfo.user) {
            window.zE('webWidget', 'prefill', {
              name: { value: userInfo.user.name, readOnly: true | false }
            });
            window.zE('webWidget', 'prefill', {
              email: { value: userInfo.user.email, readOnly: true | false }
            });
          }
        } else {
          window.zE('webWidget', 'hide');
        }
        clearInterval(interval);
      }
    };
    interval = setInterval(handleChat, 1000);
  }, [isLoggedIn, memoizedGetUserInfo]);

  return <></>;
};

export default Chat;
