import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import * as Styled from './styled';

const FileList = ({ items, onDelete }) => (
  <Styled.SelectedFilesWrapper>
    {items.map(
      (file, index) =>
        file && (
          <Chip
            label={file.name}
            onDelete={() => onDelete(index)}
            key={`selectedFile-${index}-${file.name}`}
          />
        )
    )}
  </Styled.SelectedFilesWrapper>
);

FileList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onDelete: PropTypes.func.isRequired
};

FileList.defaultProps = {
  items: []
};

export default memo(FileList);
