import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { AppContext } from '../Context';
import FormDialogActions from './FormDialogActions';

const FormDialog = ({
  initialValues,
  onClose,
  open,
  onSubmit,
  render,
  title,
  hideClearLabel,
  cancelLabel,
  clearLabel,
  submitLabel,
  ...props
}) => {
  const { actions } = useContext(AppContext);
  const { handleError } = actions;

  const handleSubmit = async (values, { setSubmitting, ...actions }) => {
    setSubmitting(true);
    try {
      await onSubmit(values, { setSubmitting, ...actions });
    } catch (error) {
      handleError(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={formikProps => (
          <Form>
            <DialogContent>{render(formikProps)}</DialogContent>
            <FormDialogActions
              initialValues={initialValues}
              onClose={onClose}
              formikProps={formikProps}
              hideClearLabel={hideClearLabel}
              cancelLabel={cancelLabel}
              clearLabel={clearLabel}
              submitLabel={submitLabel}
            />
          </Form>
        )}
        {...props}
      />
    </Dialog>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  hideClearLabel: PropTypes.bool,
  cancelLabel: PropTypes.string,
  clearLabel: PropTypes.string,
  submitLabel: PropTypes.string
};

FormDialog.defaultProps = {
  open: false,
  hideClearLabel: false,
  cancelLabel: 'Cancelar',
  clearLabel: 'Limpar',
  submitLabel: 'Salvar'
};

export default memo(FormDialog);
