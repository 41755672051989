import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0;
  color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Label = styled.span`
  color: white;
  font-size: 0.8rem;
  padding: 0.1rem 0;
  font-weight: 500;
`;

export const Value = styled.span`
  color: #f2c94c;
  font-size: 1rem;
  font-weight: bold;
`;
