import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Divider, Drawer as MUIDrawer, Typography } from '@material-ui/core';
import { compact } from 'lodash';
import { NavLink } from 'react-router-dom';
import {
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge
} from '@material-ui/core';
import { AppContext } from 'components/Context';
import BadgeNew from 'components/BadgeNew';
import {
  Connection,
  CashMultiple,
  Wallet,
  Tag,
  FormatListText,
  PlusCircleOutline,
  Cart,
  Finance,
  Bell,
  Dolly,
  TruckFast,
  MessageReplyText,
  Alarm,
  AccountMultiple,
  AccountBoxOutline,
  Puzzle,
  CashRefund,
  Star,
  AlertDecagram,
  ApplicationImport,
  Handshake
} from 'mdi-material-ui';
import { Avatar, Toolbar } from './styled';
import useBehavior from './useBehavior';

function Drawer({ classes, open, onClose, history }) {
  const { user, selectedShipper } = useBehavior();
  const {
    state: {
      hasPlan,
      hasSalesOrder,
      hasRefunds,
      hasRenegotiations,
      hasConcilations,
      ticketCountAwaitingResponse
    }
  } = React.useContext(AppContext);

  const onClickAvatar = () => {
    history.push('/configuracoes/identificacao');
    onClose();
  };

  const groups = [
    {
      label: null,
      items: [{ href: '/dashboard', icon: <Finance />, label: 'Dashboard' }]
    },
    {
      label: 'COTAÇÕES',
      items: compact([
        { href: '/cotar', icon: <PlusCircleOutline />, label: 'Nova cotação' },
        { href: '/cotacoes', icon: <FormatListText />, label: 'Minhas cotações' },
        hasSalesOrder
          ? {
              href: '/vendas?integracao=BLING',
              icon: <ApplicationImport />,
              label: 'Vendas - Fila de Contratação'
            }
          : null
      ])
    },
    {
      label: 'PEDIDOS',
      items: [
        { href: '/pedidos', icon: <Cart />, label: 'Meus pedidos' },
        { href: '/acompanharcoletas', icon: <Dolly />, label: 'Acompanhar coletas' },
        { href: '/acompanharentregas', icon: <TruckFast />, label: 'Acompanhar entregas' },
        { href: '/etiquetas', icon: <Tag />, label: 'Etiquetas' }
      ]
    },
    {
      label: 'FINANCEIRO',
      items: compact([
        { href: '/financeiro', icon: <CashMultiple />, label: 'Faturas' },
        hasConcilations
          ? { href: '/conciliacoesdefretes', icon: <Handshake />, label: 'Conciliações de fretes' }
          : null,
        hasRenegotiations
          ? { href: '/renegociacoes', icon: <AlertDecagram />, label: 'Renegociações' }
          : null,
        hasRefunds ? { href: '/reembolsos', icon: <CashRefund />, label: 'Reembolsos' } : null,
        { href: '/carteira', icon: <Wallet />, label: 'Carteira' },
        hasPlan ? { href: '/assinatura', icon: <Star />, label: 'Assinatura' } : null
      ])
    },
    {
      label: 'CENTRAL DO ATENDIMENTO',
      items: [
        {
          href: '/central-de-atendimento',
          icon: <MessageReplyText />,
          label: 'Central de atendimento',
          badgeContent: ticketCountAwaitingResponse
        }
      ]
    },
    {
      label: 'CONFIGURAÇÕES',
      items: [
        {
          href: '/configuracoes/identificacao',
          icon: <AccountBoxOutline />,
          label: 'Meus dados'
        },
        { href: '/configuracoes/colaboradores', icon: <AccountMultiple />, label: 'Colaboradores' },
        { href: '/configuracoes/horarios', icon: <Alarm />, label: 'Horários de coleta' },
        { href: '/configuracoes/tipodecarga', icon: <Puzzle />, label: 'Tipo de carga' },
        {
          href: '/configuracoes/notificacoes',
          icon: <Bell />,
          label: 'Notificações',
          showNewTag: true
        },
        { href: '/integracoes', icon: <Connection />, label: 'Integrações' }
      ]
    }
  ];

  return (
    user.name && (
      <MUIDrawer PaperProps={{ className: classes.drawerPaper }} open={open} onClose={onClose}>
        <Toolbar className={classes.drawerHeader}>
          <Avatar alt={user.name.charAt(0).toUpperCase()} src={user.avatar} onClick={onClickAvatar}>
            {!user.avatar && user.name.charAt(0).toUpperCase()}
          </Avatar>
          {user.name && (
            <Typography variant="subtitle1" component="h1">
              {user.name}
            </Typography>
          )}
          {selectedShipper && (
            <Typography variant="subtitle2" component="h2">
              {selectedShipper.name}
            </Typography>
          )}
        </Toolbar>
        <Divider />
        {groups.map(group => (
          <List
            subheader={
              group.label ? (
                <ListSubheader component="div" style={{ background: 'white' }}>
                  {group.label}
                </ListSubheader>
              ) : null
            }
          >
            {group.items.map(({ href, icon, label, badgeContent, showNewTag = false }) => (
              <ListItem
                button
                component={NavLink}
                exact
                to={href}
                key={href}
                onClick={onClose}
                className={classes.listItem}
              >
                <ListItemIcon>
                  {badgeContent ? (
                    <Badge max={99} badgeContent={badgeContent} color="error">
                      {icon}
                    </Badge>
                  ) : (
                    <>{showNewTag ? <BadgeNew>{icon}</BadgeNew> : <>{icon}</>}</>
                  )}
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </List>
        ))}
        <br />
        <br />
        <br />
        <br />
      </MUIDrawer>
    )
  );
}

Drawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  history: PropTypes.objectOf(PropTypes.any).isRequired
};

Drawer.defaultProps = {
  open: false
};

export default withRouter(Drawer);
