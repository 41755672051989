import styled from 'styled-components';
import { Tabs } from '@material-ui/core';

export const TabsContainer = styled.div``;

export default styled(Tabs)`
  color: white;
  > div {
    background: #453e97;
  }
`;
