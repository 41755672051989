export const PRIMARY_COLOR = '#453E97';
export const SECONDARY_COLOR = '#2DC1D0';
export const TERTIARY_COLOR = '#D6008F';
export const QUARTENARY_COLOR = '#FFD448';

export const ORANGE = '#FF9939';
export const BROWN = '#9E260B';
export const CYAN = '#00C6DD';
export const INDIGO = '#2C4761';
export const GREEN_WATER = '#1AB596';
export const GREEN_BLUE = '#179B83';
export const LIGHT_BLUE = '#5FA5C6';
export const DARK_BLUE = '#2A3E97';
export const HARD_BLUE = '#1B2EDD';
export const PINK = '#F84F8A';
export const RED = '#D5141F';
export const LIGHT_RED = '#EB5757';
export const DARK_RED = '#BB3E3C';
export const BLUE = '#2F80ED';
export const PURPLE = '#511683';
export const LILAC = '#CB78DB';
export const LIGHT_LILAC = '#9471A9';
export const BURGUNDY = '#9A2238';
export const GREEN = '#589756';
export const GREEN_LIGHT = '#43b318';
export const LIGHT_GREY = '#A9A292';
export const GREY = '#727272';
export const DARK_GREY = '#4F4F4F';
export const LIGHT_SECONDARY = '#EAF9FB';

export const ORANGE_1 = '#E7BE3E';
export const ORANGE_2 = '#FF9939';
export const ORANGE_3 = '#E64C00';
export const ORANGE_4 = '#BF4000';
export const ORANGE_5 = '#802800';
export const ORANGE_6 = '#E2793F';
export const ORANGE_7 = '#D17158';
