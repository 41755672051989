import { useReducer, useCallback } from 'react';
import { useQuery } from 'react-apollo';
import decode from 'jwt-decode';
import getToken from 'api/getToken';
import { TOKEN, CURRENT_SHIPPER, SENDER } from 'api/constants';
import checkStatusShipper from 'api/checkStatusShipper';
import getErrorMessage from 'shared/helpers/getErrorMessage';
import QUERY_FETCH_QUOTATIONS_ON_CART from 'graphql/queries/fetchQuotationsOnCart';

const initialState = {
  tabIndex: 0,
  searchLike: '',
  isDrawerOpen: false,
  toast: {
    message: '',
    variant: 'success'
  },
  token: getToken(),
  headerWarning: {
    message: null
  },
  hasPlan: false,
  hasCart: false,
  hasSalesOrder: false,
  hasRefunds: false,
  hasRenegotiations: false,
  hasBillingEnabled: false,
  hasConcilations: false,
  cartCount: 0,
  cartLoading: false,
  quotationsOnCart: [],
  ticketCountAwaitingResponse: 0,
  ticketCountAwaitingAnalyses: 0,
  balance: undefined,
  conciliation_credits: 0,
  conciliation_debts: 0,
  group_id: null
};

const SET_TAB = 'SET_TAB';
const SET_LIKE = 'SET_LIKE';
const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
const TOGGLE_HEADER_WARNING = 'TOGGLE_HEADER_WARNING';
const CLOSE_HEADER_WARNING = 'CLOSE_HEADER_WARNING';
const SET_TOAST = 'SET_TOAST';
const SET_TOKEN = 'SET_TOKEN';
const SET_STATUS_SHIPPER = 'SET_STATUS_SHIPPER';

const SET_HAS_PLAN = 'SET_HAS_PLAN';
const SET_BALANCE = 'SET_BALANCE';
const SET_CART_COUNT = 'SET_CART_COUNT';
const SET_CART_LOADING = 'SET_CART_LOADING';
const SET_QUOTATIONS_ON_CART = 'SET_QUOTATIONS_ON_CART';

const reducer = (state, { type, payload }) => {
  const actionTypes = {
    [SET_TAB]: () => ({ ...state, tabIndex: payload }),
    [SET_LIKE]: () => ({ ...state, searchLike: payload.searchLike }),
    [TOGGLE_DRAWER]: () => ({ ...state, isDrawerOpen: payload.isOpen }),
    [TOGGLE_HEADER_WARNING]: () => ({ ...state, headerWarning: payload }),
    [CLOSE_HEADER_WARNING]: () => {
      let hiddenWarning = JSON.parse(localStorage.getItem('hiddenWarning')) || {};
      localStorage.setItem(
        'hiddenWarning',
        JSON.stringify({ ...hiddenWarning, [state.headerWarning.status]: new Date() })
      );
      return { ...state, headerWarning: { message: null } };
    },
    [SET_TOAST]: () => ({ ...state, toast: payload }),
    [SET_TOKEN]: () => ({ ...state, token: payload.token }),
    [SET_STATUS_SHIPPER]: () => {
      return {
        ...state,
        headerWarning: payload.warningToast,
        hasPlan: payload.has_plan,
        hasCart: payload.has_cart,
        hasSalesOrder: payload.has_sales_order,
        hasRefunds: payload.has_refunds,
        hasRenegotiations: payload.has_renegotiations,
        hasBillingEnabled: payload.has_billing_enabled,
        hasConcilations: payload.has_conciliations,
        cartCount: payload.cart_count,
        ticketCountAwaitingResponse: payload.ticket_count_awaiting_response || 0,
        ticketCountAwaitingAnalyses: payload.ticket_count_awaiting_analyses || 0,
        balance: payload.balance,
        conciliation_credits: payload.conciliation_credits,
        conciliation_debts: payload.conciliation_debts,
        group_id: payload.group_id
      };
    },
    [SET_HAS_PLAN]: () => ({ ...state, hasPlan: payload }),
    [SET_CART_COUNT]: () => ({ ...state, cartCount: payload }),
    [SET_BALANCE]: () => ({ ...state, balance: payload }),
    [SET_CART_LOADING]: () => ({ ...state, cartLoading: payload }),
    [SET_QUOTATIONS_ON_CART]: () => ({
      ...state,
      quotationsOnCart: payload,
      cartLoading: false,
      cartCount: payload.length
    })
  };

  const action = actionTypes[type];
  return typeof action === 'function' ? action() : state;
};

export default () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { refetch: refetchCart } = useQuery(QUERY_FETCH_QUOTATIONS_ON_CART, {
    fetchPolicy: 'network-only',
    skip: true
  });

  const setToast = (variant = 'info') => (message = '') =>
    dispatch({
      type: SET_TOAST,
      payload: { message: getErrorMessage(message), variant }
    });

  const actions = {
    clearToast: () => setToast()(),
    handleMessage: setToast('success'),
    handleError: setToast('error'),
    handleInfo: setToast('info'),
    handleWarning: setToast('warning'),
    setTab: tabIndex => dispatch({ type: SET_TAB, payload: tabIndex }),
    setLike: searchLike => dispatch({ type: SET_LIKE, payload: { searchLike } }),
    setHasPlan: payload => dispatch({ type: SET_HAS_PLAN, payload }),
    refetchCart: async () => {
      dispatch({ type: SET_CART_LOADING, payload: true });
      const { data } = await refetchCart();
      dispatch({ type: SET_QUOTATIONS_ON_CART, payload: data.fetchQuotationsOnCart });
    },
    setToken: token => {
      localStorage.setItem(TOKEN, token);
      const decodeToken = decode(token);
      localStorage.setItem(CURRENT_SHIPPER, decodeToken.selected_shipper_id);
      const sender = decodeToken.shippers.reduce(
        (accumulator, shipper) => ({
          ...accumulator,
          [shipper.id]: {
            zipcode: shipper.zipcode,
            address: shipper.address
          }
        }),
        {}
      );
      localStorage.setItem(SENDER, JSON.stringify(sender));
      dispatch({ type: SET_TOKEN, payload: { token } });
    },
    checkStatusShipper: async () => {
      const payload = await checkStatusShipper();
      dispatch({ type: SET_STATUS_SHIPPER, payload });
    },
    toggleDrawer: isOpen => dispatch({ type: TOGGLE_DRAWER, payload: { isOpen } }),
    closeHeaderWarning: () => {
      dispatch({ type: CLOSE_HEADER_WARNING });
    },
    toggleHeaderWarning: (props = { message: '' }) =>
      dispatch({ type: TOGGLE_HEADER_WARNING, payload: props })
  };

  const getters = {
    getInfoUser: () => {
      const { token } = state;
      return token ? decode(token) : {};
    },
    isBetaGroup: useCallback(() => state.group_id === 5, [state])
  };

  return { actions, getters, state };
};
