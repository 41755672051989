import React, { memo, useContext } from 'react';
import { findIndex } from 'lodash';
import { Toolbar, IconButton, useScrollTrigger, Slide, Tab, Grid, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import HeaderWarning from 'components/HeaderWarning';
import FixedTabs from 'components/FixedTabs';
import BadgeStatic from 'components/BadgeStatic';
import { AppContext } from 'components/Context';
import { Search, SearchToolbarMobile } from './AppBarSearch';
import Title from './AppBarTitle';
import Actions from './AppBarActions';
import { Wrapper, ToolbarTitle } from './styled';
import useDebounce from 'hooks/useDebounce';
import parseSearchLocation from 'shared/helpers/parseSearchLocation';

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const AppBar = ({
  location,
  match,
  history,
  returnPage,
  showSearch,
  searchPlaceholder = 'Procurar...',
  pageTitle,
  tabs
}) => {
  const { search } = parseSearchLocation(location.search);
  const { actions, state } = useContext(AppContext);
  const [isSearching, setSearching] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState(search);
  const isShowingHeaderWarning = Boolean(state.headerWarning.message);
  const { isDrawerOpen, headerWarning } = state;
  const { toggleDrawer, setLike, closeHeaderWarning } = actions;
  const debouncedSearch = useDebounce({ value: searchTerm });
  const memoizedSetLike = React.useCallback(setLike, [debouncedSearch]);

  const handleChangeSearch = ev => {
    setSearchTerm(ev.target.value);
  };

  React.useEffect(() => {
    memoizedSetLike(debouncedSearch);
  }, [debouncedSearch, memoizedSetLike]);

  React.useEffect(() => {
    const tabIndex = findIndex(tabs, ({ to }) => to === match.url);
    const minTabIndex = tabIndex >= 0 ? tabIndex : 0;
    if (minTabIndex !== state.tabIndex) {
      actions.setTab(minTabIndex);
    }
  }, [actions, match.url, state.tabIndex, tabs]);

  return (
    <HideOnScroll>
      <Wrapper position="sticky">
        {isShowingHeaderWarning && (
          <HeaderWarning {...headerWarning} onClose={closeHeaderWarning} />
        )}
        {!isSearching && (
          <Toolbar>
            {returnPage ? (
              <IconButton color="inherit" onClick={() => history.push(returnPage)}>
                <ArrowBackIcon />
              </IconButton>
            ) : (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="Open drawer"
                onClick={() => toggleDrawer(!isDrawerOpen)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Title title={pageTitle} flexGrow={showSearch ? 0 : 1} />
            {showSearch && (
              <Search
                placeholder={searchPlaceholder}
                value={searchTerm}
                handleChange={handleChangeSearch}
                setSearching={setSearching}
              />
            )}
            <Actions history={history} />
          </Toolbar>
        )}
        {isSearching && showSearch && (
          <SearchToolbarMobile
            placeholder={searchPlaceholder}
            value={searchTerm}
            handleChange={handleChangeSearch}
            setSearching={setSearching}
          />
        )}
        {!tabs && (
          <Box display={{ xs: 'block', sm: 'none' }}>
            <Toolbar>
              <ToolbarTitle variant="h6">{pageTitle}</ToolbarTitle>
            </Toolbar>
          </Box>
        )}
        {tabs && (
          <FixedTabs scrollButtons="auto" variant="scrollable" value={state.tabIndex}>
            {tabs.map(tab => {
              const count = tab.count ? tab.count(state) : null;
              return (
                <Tab
                  label={
                    tab.count ? (
                      <Grid container spacing={1} justify="center">
                        <Grid item>{tab.label}</Grid>
                        <Grid item>
                          <BadgeStatic badgeContent={count} color="error" />
                        </Grid>
                      </Grid>
                    ) : (
                      tab.label
                    )
                  }
                  component={Link}
                  to={tab.to}
                  style={{
                    flexGrow: 1,
                    maxWidth: 'none'
                  }}
                />
              );
            })}
          </FixedTabs>
        )}
      </Wrapper>
    </HideOnScroll>
  );
};

export default memo(AppBar);
