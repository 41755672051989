import React, { Suspense, memo } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Switch } from 'react-router-dom';
import Route from './Route';
import { privateRoutes, publicRoutes } from './routes';
import Chat from 'components/Chat';
import Drawer from 'components/Drawer';
import Footer from 'components/Footer';

const hasToken = () => {
  return localStorage.getItem('token') !== null;
};

const useStyles = makeStyles(() => ({
  drawerPaper: {
    zIndex: 999999,
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#ebebeb',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: '#bdbdbd'
    }
  },
  drawerHeader: {
    background: '#39337D'
  },
  listItem: {
    '&.active': {
      color: '#453E97',
      '& > .MuiListItemIcon-root': {
        color: '#453E97 !important'
      }
    }
  }
}));

const Router = ({ open, onClose }) => {
  const isLoggedIn = hasToken();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Chat isLoggedIn={isLoggedIn} />
      <Drawer open={open} onClose={onClose} classes={classes} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <Suspense fallback={<LinearProgress />}>
          <Switch>
            {publicRoutes.map(route => (
              <Route {...route} key={route.path} />
            ))}
            {privateRoutes.map(route => (
              <Route {...route} isPrivate hasToken={isLoggedIn} key={route.path} />
            ))}
          </Switch>
        </Suspense>
        <Footer />
      </main>
    </div>
  );
};

Router.propTypes = {
  children: PropTypes.node
};

Router.defaultProps = {
  children: null
};

export default memo(Router);
