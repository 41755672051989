import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';

export const Link = styled(RLink)`
  text-decoration: none;
  outline: none;
`;

export const MenuDividerText = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.56);
  line-height: 24px;
  font-weight: 500px;
  padding: 9px 16px;
  outline: none;
`;

export const MenuItemText = styled.li`
  padding: 0 16px 16px;
  font-size: 16px;
  outline: none;
`;
