import * as Yup from 'yup';

export const requiredValidation = (validation, message = 'Campo obrigatório') =>
  validation.required(message);

export const phoneValidation = Yup.string()
  .min(10, 'Informe um telefone válido')
  .max(11, 'Informe um telefone válido');

export const documentValidation = Yup.string().test(
  'checkDocument',
  'Informe um documento válido',
  document => {
    const cleanDocument = document ? document.replace(/[./-]/gi, '') : '';
    const length = String(cleanDocument).length;
    return length === 0 || length === 14 || length === 11;
  }
);

export const cpfValidation = Yup.string()
  .min(11, 'Informe um CPF válido')
  .max(11, 'Informe um CPF válido');

export const cnpjValidation = Yup.string()
  .min(14, 'Informe um CNPJ válido')
  .max(14, 'Informe um CNPJ válido');

export const emailValidation = Yup.string().email('Informe um email válido');
