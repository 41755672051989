import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Divider, Menu as MUIMenu } from '@material-ui/core';
import MenuItem from './MenuItem';

const Menu = ({
  children,
  footer,
  handleClose,
  anchorEl,
  items,
  menuItemProps,
  id,
  MenuListProps
}) => {
  return (
    <MUIMenu
      id={id}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      MenuListProps={MenuListProps}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      {children}
      {children && <Divider />}
      {items.map((item, index) => (
        <MenuItem
          handleCloseMenu={handleClose}
          key={index.toString()}
          {...item}
          {...(menuItemProps ? menuItemProps(item) : {})}
        />
      ))}
      {footer}
    </MUIMenu>
  );
};

Menu.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.objectOf(PropTypes.any),
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  renderItem: PropTypes.func,
  id: PropTypes.string.isRequired,
  MenuListProps: PropTypes.object
};

Menu.defaultProps = {
  children: null,
  footer: null,
  anchorEl: null,
  renderItem: null,
  MenuListProps: undefined
};

export default memo(Menu);
