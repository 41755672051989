import React, { memo, useContext } from 'react';
import { CircularProgress } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import BadgeButton from 'components/BadgeButton';
import { AppContext } from 'components/Context';
import useMenu from 'hooks/useMenu';
import ShoppingCartMenu from './menu';

const ShoppingCart = ({ history }) => {
  const { anchorEl, handleOpenMenu, handleCloseMenu } = useMenu();
  const {
    state,
    actions: { refetchCart }
  } = useContext(AppContext);

  const onOpenMenu = event => {
    refetchCart();
    handleOpenMenu(event);
  };

  return (
    <>
      {state.hasCart && (
        <>
          <BadgeButton
            ariaLabel="Meu Carrinho"
            aria-controls="cart-menu"
            aria-haspopup="true"
            onClick={onOpenMenu}
            badgeContent={state.cartCount}
          >
            {state.cartLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <ShoppingCartIcon />
            )}
          </BadgeButton>
          <ShoppingCartMenu
            anchorEl={anchorEl}
            handleCloseMenu={handleCloseMenu}
            history={history}
          />
        </>
      )}
    </>
  );
};

export default memo(ShoppingCart);
