import React, { memo, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Wrapper, Image } from './styled';
import Logo from 'assets/logo.png';
import { publicRoutes } from 'router/routes';

const routesToHideFooter = [...publicRoutes.map(({ path }) => path), '/'];

const Footer = ({ history }) => {
  const [isShowing, dispatch] = useState(false);

  useEffect(() => {
    dispatch(!routesToHideFooter.includes(window.location.pathname));

    const listener = history.listen(location => {
      dispatch(!routesToHideFooter.includes(location.pathname));
    });

    return () => {
      listener();
    };
  }, [history]);

  return (
    isShowing && (
      <Wrapper>
        <Image src={Logo} alt="Central do Frete" />
        &copy;{new Date().getFullYear()}
      </Wrapper>
    )
  );
};

export default memo(withRouter(Footer));
