import React from 'react';
import { IconButton, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { InputDesktop, InputMobile, Toolbar, Wrapper } from './styled';
import BadgeButton from 'components/BadgeButton';

export const Search = ({ placeholder, value, handleChange, setSearching }) => (
  <>
    <Box display={{ xs: 'none', sm: 'block' }} style={{ flexGrow: 1 }}>
      <Wrapper>
        <SearchIcon />
        <InputDesktop
          placeholder={placeholder}
          inputProps={{ 'aria-label': value }}
          value={value}
          onChange={handleChange}
        />
      </Wrapper>
    </Box>
    <Box display={{ xs: 'block', sm: 'none' }}>
      <BadgeButton ariaLabel="Pesquisar" onClick={() => setSearching(true)}>
        <SearchIcon />
      </BadgeButton>
    </Box>
  </>
);

export const SearchToolbarMobile = ({ placeholder, value, handleChange, setSearching }) => (
  <Box display={{ xs: 'block', sm: 'none' }}>
    <Toolbar>
      <IconButton color="inherit" aria-label="Menu" onClick={() => setSearching(false)}>
        <ArrowBackIcon />
      </IconButton>

      <InputMobile
        type="search"
        className="inputSearch"
        placeholder={placeholder}
        value={value}
        disableUnderline
        autoFocus
        onChange={handleChange}
      />
    </Toolbar>
  </Box>
);
