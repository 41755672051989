import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MenuItem as MUIMenuItem, ListItemIcon, Icon, Typography, Badge } from '@material-ui/core';
import Wrapper from '../MenuItemWrapper';

const MenuItem = ({
  href,
  text,
  onClick,
  handleCloseMenu,
  icon,
  renderSecondaryAction,
  badgeCount,
  badgeColor
}) => {
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
    handleCloseMenu();
  };

  return (
    <Wrapper href={href}>
      <MUIMenuItem key={`menu-item-${text}`} onClick={handleClick}>
        {icon && (
          <ListItemIcon>
            <Badge max={99} badgeContent={badgeCount} color={badgeColor}>
              <Icon>{icon}</Icon>
            </Badge>
          </ListItemIcon>
        )}
        <Typography variant="body2" color="textPrimary">
          {text}
        </Typography>
        {renderSecondaryAction()}
      </MUIMenuItem>
    </Wrapper>
  );
};

MenuItem.propTypes = {
  badgeCount: PropTypes.number,
  badgeColor: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  handleCloseMenu: PropTypes.func.isRequired,
  renderSecondaryAction: PropTypes.func
};

MenuItem.defaultProps = {
  badgeCount: 0,
  badgeColor: 'primary',
  href: '',
  icon: null,
  onClick: () => {},
  renderSecondaryAction: () => {}
};

export default memo(MenuItem);
