import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useBehavior from './useBehavior';

export const AppContext = React.createContext();

const Provider = ({ children }) => {
  const { actions, state, getters } = useBehavior();
  return <AppContext.Provider value={{ state, actions, getters }}>{children}</AppContext.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node.isRequired
};

export const Consumer = AppContext.Consumer;
export const withAppContext = Component => props => (
  <Consumer>{contextState => <Component {...props} appContext={contextState} />}</Consumer>
);
export default memo(Provider);
