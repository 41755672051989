import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';

export const Link = styled(RLink)`
  text-decoration: none;
  color: inherit;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-self: flex-end;
`;
