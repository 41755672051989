import React from 'react';
import { Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StaticBadge = withStyles(theme => ({
  root: {
    position: 'static',
    marginBottom: '2px'
  },
  badge: {
    position: 'static',
    transform: 'none'
  }
}))(Badge);

export default React.memo(StaticBadge);
