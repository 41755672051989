import React from 'react';
import { Box } from '@material-ui/core';
import { Title } from './styled';

const AppBarTitle = ({ title, flexGrow }) => (
  <>
    <Box display={{ xs: 'none', sm: 'block' }} style={{ flexGrow }}>
      <Title variant="h6" noWrap>
        {title}
      </Title>
    </Box>
    <Box display={{ xs: 'block', sm: 'none' }} style={{ flexGrow: 1 }} />
  </>
);

export default React.memo(AppBarTitle);
