import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from '../styled';

const Wrapper = ({ href, children }) => {
  if (!href) {
    return <>{children}</>;
  }
  if (href.charAt(0) === '/') {
    return <Link to={href}>{children}</Link>;
  }

  const aditionalProps = href.toLowerCase().startsWith('http')
    ? {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    : {};

  return (
    <a href={href} {...aditionalProps} style={{ textDecoration: 'none', color: 'inherit' }}>
      {children}
    </a>
  );
};

Wrapper.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired
};

Wrapper.defaultProps = {
  href: ''
};

export default memo(Wrapper);
