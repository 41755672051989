/* eslint-disable no-console */
import { get } from 'lodash';
import {
  AUTH_USER_UNVERIFIED_EMAIL,
  AUTH_USER_NOT_FOUND,
  AUTH_INVALID_TOKEN,
  AUTH_USER_SHIPPER_NOT_FOUND,
  AUTH_USER_SHIPPER_NOT_RELATED
} from 'shared/constants/errors';
import { logout } from './user';

const AUTH_ERRORS = [
  AUTH_USER_NOT_FOUND,
  AUTH_INVALID_TOKEN,
  AUTH_USER_SHIPPER_NOT_FOUND,
  AUTH_USER_SHIPPER_NOT_RELATED
];

const isAuthMessage = errorCode => (errorCode ? AUTH_ERRORS.includes(errorCode) : false);

const needToLogout = ({ statusCode = '', errorCode = null }) =>
  statusCode === 401 || isAuthMessage(errorCode);

export default function errorHandler({ graphqlErrors = [], networkError = {}, response }) {
  const errorCode = get(response, 'errors[0].code', null);
  if (needToLogout({ statusCode: networkError.statusCode, errorCode })) {
    logout();
    return;
  }

  if (errorCode === AUTH_USER_UNVERIFIED_EMAIL) {
    window.location.href = '/confirmar-email';
  }

  if (networkError.message) {
    console.error(`[Network error]: ${networkError.message}`);
  }

  // eslint-disable-next-line no-unused-vars
  for (let { message, locations, path } of graphqlErrors) {
    console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
  }
}
