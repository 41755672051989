import styled from 'styled-components';
import { Link } from 'react-router-dom';

const defaultStyle = `
  color: #2f80ed;
  text-decoration: underline;
  &:hover {
    color: #25579a;
  }
`;

export const InternalLink = styled(Link)`
  ${defaultStyle}
  ${({ caption }) => caption && `font-size: 0.7em; `}
`;

export default styled.a`
  ${defaultStyle}
  ${({ caption }) => caption && `font-size: 0.7em; `}
`;
