import { useReducer, useContext, useMemo } from 'react';
import { find } from 'lodash';
import { selectShipper } from 'api/user';
import { AppContext } from '../Context';

const reducer = (state, { type, payload }) => {
  const actions = {
    toggleUseLetter: () => {
      return { ...state, useLetter: payload };
    }
  };
  return typeof actions[type] === 'function' ? actions[type]() : state;
};

const useBehavior = () => {
  const [state, dispatch] = useReducer(reducer, { useLetter: false });

  const toggleUseLetter = payload => dispatch({ type: 'toggleUseLetter', payload });
  const { actions, getters } = useContext(AppContext);
  const { getInfoUser } = getters;
  const { handleError, setToken } = actions;

  const infoUser = useMemo(() => getInfoUser(), [getInfoUser]);
  const { user = { name: '' }, shippers = [], selected_shipper_id: selectedShipperId } =
    infoUser || {};
  const selectedShipper = useMemo(
    () => find(shippers, shipper => shipper.id === selectedShipperId),
    [shippers, selectedShipperId]
  );

  const handleChangeShipper = async shipperId => {
    try {
      const token = await selectShipper(shipperId);
      setToken(token);
      window.location.reload(true);
    } catch (error) {
      handleError(error);
    }
  };

  return {
    infoUser,
    user,
    shippers,
    selectedShipper,
    selectedShipperId,
    state,
    toggleUseLetter,
    handleChangeShipper
  };
};

export default useBehavior;
