/* eslint-disable no-console */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import errorHandler from './errorHandle';
import { ApolloLink, Observable } from 'apollo-link';
import getToken from './getToken';
import { withClientState } from 'apollo-link-state';

const { REACT_APP_GRAPHQL_URL } = process.env;

const cache = new InMemoryCache();

const request = async operation => {
  const token = await getToken();
  const headers = token ? { authorization: `Bearer ${token}` } : undefined;
  operation.setContext({
    headers
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(errorHandler),
    requestLink,
    withClientState({
      defaults: {
        isConnected: true
      },
      resolvers: {
        Mutation: {
          updateNetworkStatus: (_, { isConnected }, { cache }) => {
            cache.writeData({ data: { isConnected } });
            return null;
          }
        }
      },
      cache
    }),
    createUploadLink({
      uri: REACT_APP_GRAPHQL_URL
    })
  ]),
  cache
});

export default client;
