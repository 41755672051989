export const AUTH_ERROR_MESSAGE = 'Nenhum embarcador selecionado';
export const TOKEN = 'token';
export const CURRENT_SHIPPER = 'current_shipper';
export const SENDER = 'sender';
export const HOME_PATH = '/';
export const STATUS_CODE_500 = 500;
export const STATUS_CODE_404 = 404;
export const STATUS_CODE_INVALID = 300;

export default {
  AUTH_ERROR_MESSAGE,
  TOKEN,
  CURRENT_SHIPPER,
  SENDER,
  HOME_PATH,
  STATUS_CODE_500,
  STATUS_CODE_404,
  STATUS_CODE_INVALID
};
