import styled from 'styled-components';
import { Typography, AppBar } from '@material-ui/core';

export const Wrapper = styled(AppBar)`
  @media print {
    display: none !important;
  }
`;

export const ToolbarTitle = styled(Typography)`
  color: white !important;
  text-align: left;
  margin-left: 36px !important;
`;
