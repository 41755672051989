import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import useCopy from 'hooks/useCopy';
import Button from './styled';

const CopyButton = ({ text, textLimit, showInnerText, ...props }) => {
  const [onClick] = useCopy({ text });
  const formatedText =
    textLimit && text.length > textLimit ? `${text.substring(0, textLimit)}...` : text;

  return (
    <Tooltip title="Copiar para a área de transferência">
      {showInnerText ? (
        <Button onClick={onClick} {...props}>
          {formatedText} <FileCopyIcon />
        </Button>
      ) : (
        <IconButton aria-label="Copy" size="small" onClick={onClick}>
          <FileCopyIcon fontSize="small" />
        </IconButton>
      )}
    </Tooltip>
  );
};

CopyButton.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string.isRequired,
  textLimit: PropTypes.number,
  showInnerText: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string
};

CopyButton.defaultProps = {
  color: 'default',
  textLimit: undefined,
  showInnerText: true,
  variant: 'contained',
  size: undefined
};

export default memo(CopyButton);
