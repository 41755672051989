import { useState } from 'react';

const useDialog = (initialValue = false) => {
  const [isOpen, setIsOpen] = useState(initialValue);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return { isOpen, handleOpen, handleClose };
};

export default useDialog;
