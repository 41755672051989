import styled from 'styled-components';
import { Avatar as MUIAvatar, Button } from '@material-ui/core';

export const Avatar = styled(MUIAvatar)`
  background-color: white !important;
  width: 26px !important;
  height: 26px !important;
  box-sizing: border-box;
  margin: 0 8px 0;
  color: #453e97 !important;
  font-size: 0.9rem !important;

  img {
    margin-top: 0;
  }
`;

export const MenuButton = styled(Button)`
  color: white !important;
`;

export const BusinessName = styled.span`
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: 600px) {
    display: none;
  }
`;
