import React, { memo } from 'react';
import styled from 'styled-components';
import { Badge } from '@material-ui/core';

export const StyledBadge = styled(Badge)`
  > span:last-child {
    color: #29231f !important;
    background-color: #ffc107 !important;
  }
`;

const BadgeNew = ({ children, label = 'novo', variant }) => (
  <StyledBadge badgeContent={label} variant={variant}>
    {children}
  </StyledBadge>
);

export default memo(BadgeNew);
