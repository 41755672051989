import React from 'react';
import AddIcon from '@material-ui/icons/AddCircle';
import BadgeButton from '../../BadgeButton';
import ProfileMenu from '../../ProfileMenu';
import HelpMenu from '../../HelpMenu';
import ShoppingCart from '../../ShoppingCart';
import { Link, Wrapper } from './styled';

const AppBarActions = ({ history }) => {
  return (
    <Wrapper>
      <Link to="/cotar">
        <BadgeButton ariaLabel="Criar cotação">
          <AddIcon />
        </BadgeButton>
      </Link>
      <HelpMenu />
      <ShoppingCart history={history} />
      <ProfileMenu history={history} />
    </Wrapper>
  );
};

export default AppBarActions;
